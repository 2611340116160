import { Card, Image, Container, Heading, Text } from "@chakra-ui/react"

export const LeadsCard = ({ isHPV }) => {
	return (
		<Card mt="20px" display="flex" flexDirection="row" maxW="xl" p="20px">
			<Image
				src={
					isHPV
						? process.env.PUBLIC_URL + "/assets/hpv_clipboard_icon.svg"
						: process.env.PUBLIC_URL + "/assets/clipboard_icon.svg"
				}
				alt="Task Icon"
				w="65px"
				h="65px"
			/>
			<Container>
				<Heading size="md" fontWeight="bold" pb="5px">
					Grow your business as a{" "}
					{isHPV ? "skilled Home Pro" : "skilled Vendor"}
				</Heading>
				<Text>
					{isHPV
						? "Grow your business with trusted referrals and repeat business."
						: "Grow your business with trusted leads, repeat business, and NO lead fees."}
				</Text>
			</Container>
		</Card>
	)
}
