import { useEffect, useContext, useState } from "react"
import { Box } from "@chakra-ui/react"
import { useNavigate, useLocation } from "react-router-dom"
import { UserContext } from "./Contexts/UserContext.js"
import { VendorLoggedInNav } from "./VendorLoggedInNav.js"
import { AgentLoggedInNav } from "./AgentLoggedInNav.js"
import { ClientLoggedInNav } from "./ClientLoggedInNav.js"

export const NavWrapper = () => {
	const navigate = useNavigate()
	const { userPlus } = useContext(UserContext)
	const [isLoading, setIsLoading] = useState(true)
	const location = useLocation()

	useEffect(() => {
		if (userPlus === "pending" || (userPlus !== null && !userPlus?.userType)) {
			return
		}
		if (userPlus === null) {
			navigate("/login", { state: { from: location } })
		} else {
			setIsLoading(false)
		}
	}, [userPlus])

	if (isLoading) {
		return <Box h="70px" w="100%" />
	}

	return userPlus?.userType === "vendor" ? (
		<VendorLoggedInNav userPlus={userPlus} />
	) : userPlus?.userType === "agent" || userPlus?.userType === "TC" ? (
		<AgentLoggedInNav userPlus={userPlus} />
	) : (
		<ClientLoggedInNav userPlus={userPlus} />
	)
}
