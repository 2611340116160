import { Box, HStack, Image, Text, Tag, TagLabel } from "@chakra-ui/react"
import { dateToFormat, convertToAMPM } from "../Utilities"

export const JobDetailsInformationBox = ({ jobRequest, userPlus }) => {
	return (
		<Box
			bgColor="white"
			boxShadow="md"
			borderRadius="md"
			display="flex"
			position="relative"
		>
			<Image
				src={
					userPlus.partner === "HPV"
						? "/assets/hpv_house_banner_icon.svg"
						: "/assets/house_banner_icon.svg"
				}
				position={{ md: "absolute" }}
				display={{ base: "none", lg: "flex" }}
			/>
			<Image
				src={
					userPlus.partner === "HPV"
						? "/assets/hpv_house_banner_icon2.svg"
						: "/assets/house_banner_icon2.svg"
				}
				position="absolute"
				top="0"
				right="0"
				display={{ base: "flex", lg: "none" }}
			/>
			<Box
				display={{ lg: "flex" }}
				width={{ base: "100%", lg: "80%" }}
				justifyContent="space-between"
				p="20px"
				ml={{ lg: "125px" }}
				mt={{ base: "25px", lg: "0" }}
			>
				<Box ml={{ lg: "10px" }}>
					<HStack alignItems={{ base: "center", lg: "start" }}>
						<Image src="/assets/user_group.svg" />
						<Text fontWeight="semibold" fontSize="lg">
							Client Info
						</Text>
					</HStack>
					<Box mt={{ base: "5px", lg: "15px" }}>
						<Text>{jobRequest?.locationAddress}</Text>
						{jobRequest?.clientEmailAddress &&
							jobRequest?.clientEmailAddress.map((client, index) => (
								<Tag
									key={index}
									borderRadius="full"
									variant="solid"
									bgColor="borderColor"
									mr="5px"
									mt="5px"
									mb="5px"
								>
									<TagLabel>{client}</TagLabel>
								</Tag>
							))}
					</Box>
				</Box>
				<Box ml={{ lg: "30px" }} mt={{ base: "10px", lg: "0px" }}>
					<HStack alignItems={{ base: "center", md: "start" }}>
						<Image src="/assets/truck.svg" />
						<Text fontWeight="semibold" fontSize="lg">
							Preferred Dates & Times
						</Text>
					</HStack>
					<Box mt={{ base: "5px", lg: "15px" }}>
						{jobRequest?.servicePreferredDates?.map((entry, index) => (
							<HStack gap={5} mb="5px" key={index}>
								<Text key={index}>
									{dateToFormat(entry.date)} {convertToAMPM(entry.startTime)} -{" "}
									{convertToAMPM(entry.endTime)}
								</Text>
							</HStack>
						))}
					</Box>
				</Box>
				<Box ml={{ lg: "100px" }} mt={{ base: "10px", lg: "0px" }}>
					<HStack alignItems={{ base: "center", md: "start" }}>
						<Image src="/assets/clipboard_list.svg" />
						<Text fontWeight="semibold" fontSize="lg">
							{jobRequest?.serviceType}{" "}
							{jobRequest?.subServiceType && (
								<Text fontSize="md">- {jobRequest?.subServiceType}</Text>
							)}
						</Text>
					</HStack>

					<Box mt={{ base: "5px", lg: "15px" }}>
						{jobRequest?.jobRequestDetails && (
							<Text>{jobRequest?.jobRequestDetails}</Text>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	)
}
