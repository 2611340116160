import { useContext } from "react"
import { NavLink, useLocation } from "react-router-dom"
import {
	Box,
	Button,
	Card,
	CardHeader,
	CardBody,
	Stack,
	StackDivider,
	Text,
} from "@chakra-ui/react"
import { dateToFormat } from "../Utilities"
import { UserContext } from "../Contexts/UserContext"

export const JobRequestsMobileCard = ({ jobRequest, index }) => {
	const location = useLocation()
	const { userPlus } = useContext(UserContext)

	return (
		<Card w="100%" mb="20px" key={index}>
			<CardHeader fontWeight="semibold">
				{jobRequest.locationAddress}
			</CardHeader>
			<CardBody fontSize="sm">
				<Stack divider={<StackDivider />} spacing="1">
					<Box display="flex" justifyContent="space-between">
						<Text fontWeight="semibold">Service Type:</Text>
						<Text> {jobRequest.serviceType}</Text>
					</Box>
					{jobRequest.vendorsAvailable && (
						<Box display="flex" justifyContent="space-between">
							<Text fontWeight="semibold">Vendor Responses:</Text>
							<Text> {jobRequest.vendorsAvailable}</Text>
						</Box>
					)}
					<Box display="flex" justifyContent="space-between">
						<Text fontWeight="semibold">Preferred Dates:</Text>
						<Box>
							{jobRequest.servicePreferredDates.map((entry, index) => (
								<Stack gap={5} mb="5px" key={index}>
									<Text key={index}>{dateToFormat(entry.date)}</Text>
								</Stack>
							))}
						</Box>
					</Box>
					<Box display="flex" justifyContent="space-between">
						<Text fontWeight="semibold">Job Status:</Text>
						{jobRequest.selectedResponseId && (
							<>
								{!userPlus.partner && (
									<Text>
										Request to Schedule -{" "}
										{dateToFormat(jobRequest.scheduleDate)}
									</Text>
								)}
								{userPlus.partner && <Text>Request Booking</Text>}
							</>
						)}
						{jobRequest.vendorsAvailable >= 1 &&
							!jobRequest.selectedResponseId && <Text>In Progress</Text>}
						{!jobRequest.vendorsAvailable && <Text> Awaiting Responses</Text>}
					</Box>
				</Stack>
				<Button
					as={NavLink}
					to={{
						pathname: `/job-requests/responses/${jobRequest.id}`,
						state: { from: location.pathname },
					}}
					w="100%"
					mt="10px"
				>
					View Details
				</Button>
			</CardBody>
		</Card>
	)
}
