import { Box, Button, Image, Text } from "@chakra-ui/react"

export const HPVBanner = ({ location, userPlus }) => {
	return (
		<>
			<Box
				w="100%"
				bgColor="blue.200"
				display={{ md: "flex" }}
				justifyContent="space-between"
				p="20px"
			>
				<Box>
					<Box display="flex" alignItems="center">
						<Image
							src="/assets/companyLogos/HPV_logo.svg"
							color="blue.700"
							my="auto"
							mr="10px"
						/>
						{location === "jobRequestResponseList" && (
							<Text color="blue.700" fontWeight="semibold" fontSize="xl">
								Home Pro Valet Discount!
							</Text>
						)}
						{location === "inviteNoSub" && (
							<Text color="blue.700" fontWeight="semibold" fontSize="xl">
								Upgrade your Home Pro Valet account
							</Text>
						)}
						{location === "invite" && (
							<Text color="blue.700" fontWeight="semibold" fontSize="xl">
								Help clients make homeownership easier!
							</Text>
						)}
					</Box>
					{location === "jobRequestResponseList" && (
						<Text>
							Receive <b>discount coupons</b> when booking a Home Pro Valet
							vendor.
						</Text>
					)}
					{location === "inviteNoSub" && (
						<Text>
							Gift your clients access to a trusted network with discounts.
							Unlock premium features to help grow your business.
						</Text>
					)}
					{location === "invite" && (
						<Text>
							Purchase a HomePro Valet Membership for your clients! Simplify
							home maintenance, save time & money, and get reliable service when
							you need it.
						</Text>
					)}
				</Box>
				{location === "inviteNoSub" && (
					<Button
						as="a"
						href={
							userPlus.userType === "vendor"
								? "https://pay.homeprovalet.com/b/cN23eY4Gbdr0gCs8ww"
								: "https://pay.homeprovalet.com/b/fZe7ve8Wr9aK3PG4gh"
						}
						bgColor="blue.700"
						color="white"
						borderRadius="full"
						w={{ base: "100%", md: "auto" }}
						mt={{ base: "10px", md: "0px" }}
						size="lg"
						target="_blank"
						rel="noopener noreferrer"
					>
						Upgrade
					</Button>
				)}
			</Box>
		</>
	)
}
