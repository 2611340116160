import { useState, useContext, useEffect } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { updateDoc } from "firebase/firestore"
import {
	Button,
	Center,
	Heading,
	FormControl,
	FormHelperText,
	FormErrorMessage,
	FormLabel,
	Input,
	Image,
	Stack,
	Spinner,
	Text,
} from "@chakra-ui/react"
import PhoneInput from "react-phone-number-input/input"
import { isValidPhoneNumber } from "react-phone-number-input"
import { collection, query, where, getDocs } from "firebase/firestore"
import { db, logUserEvent } from "../../firebase"
import { analytic_events } from "../../analytics"
import { UserContext } from "../../Contexts/UserContext"
import config from "../../config.js"

export const VendorProfile = () => {
	const navigate = useNavigate()
	const { userPlus } = useContext(UserContext)
	const [vendorData, setVendorData] = useState({
		name: null,
		email: null,
		phoneNumber: null,
		googlePlaceId: null,
		calendlyLink: null,
	})
	const [pageLoading, setPageLoading] = useState(false)
	const [formError, setFormError] = useState(false)

	useEffect(() => {
		if (userPlus === "pending") {
			return
		} else {
			setVendorData({
				name: userPlus.name,
				email: userPlus.email,
				phoneNumber: userPlus.phoneNumber ? userPlus.phoneNumber : null,
				googlePlaceId: userPlus.googlePlaceId ? userPlus.googlePlaceId : null,
				calendlyLink: userPlus.calendlyLink ? userPlus.calendlyLink : null,
			})
		}
	}, [userPlus])

	const verifyVendorInformation = () => {
		const hasError =
			!vendorData.name ||
			(!vendorData.companyEmail && !vendorData.phoneNumber) ||
			(!vendorData.phoneNumber && !isValidPhoneNumber(vendorData.phoneNumber))

		if (hasError) {
			alert("Please correct fields before proceeding.")
		}
		setFormError(hasError)
		return hasError
	}

	const onSubmit = async (e) => {
		e.preventDefault()
		setPageLoading(true)
		const hasError = verifyVendorInformation()
		if (hasError) {
			setPageLoading(false)
			return
		}

		let ratingValue, reviewCount
		try {
			const response = await axios.get(`${config.baseURL}/api/place-details`, {
				params: {
					placeId: vendorData.googlePlaceId,
				},
			})
			ratingValue = response.data.result.rating
			reviewCount = response.data.result.user_ratings_total
		} catch (error) {
			console.error("Error fetching place details:", error)
		}

		await updateDoc(userPlus.userDocRef, {
			_updatedAt: new Date(),
			name: vendorData.name,
			phoneNumber: vendorData.phoneNumber,
			googlePlaceId: vendorData.googlePlaceId ? vendorData.googlePlaceId : null,
			googleRating: ratingValue ? ratingValue : null,
			googleReviewCount: reviewCount ? reviewCount : null,
			...(userPlus.partner && { calendlyLink: vendorData.calendlyLink }),
		})

		const q = query(
			collection(db, "services"),
			where("userId", "==", userPlus.id)
		)

		const querySnapshot = await getDocs(q)

		const updatePromises = querySnapshot.docs.map((doc) =>
			updateDoc(doc.ref, {
				_updatedAt: new Date(),
				name: vendorData.name,
				contactEmail: userPlus.email,
				phoneNumber: vendorData.phoneNumber,
				leadEmails: vendorData.email,
				googlePlaceId: vendorData.googlePlaceId
					? vendorData.googlePlaceId
					: null,
				googleRating: ratingValue ? ratingValue : null,
				googleReviewCount: reviewCount ? reviewCount : null,
				calendlyLink: vendorData.calendlyLink ? vendorData.calendlyLink : null,
			})
		)

		await Promise.all(updatePromises)

		await logUserEvent(
			analytic_events.VENDOR_USER_PROFILE_UPDATE,
			userPlus.id,
			{
				name: vendorData.name,
			}
		)
		navigate("/services")
	}

	const handleChange = (input, e) => {
		setVendorData({ ...vendorData, [input]: e })
	}

	if (pageLoading) {
		return (
			<Center mt="50px">
				<Stack>
					<Spinner m="auto" />
				</Stack>
			</Center>
		)
	}

	return (
		<Center
			flexDirection="column"
			maxW="629px"
			margin={{ base: "15px", md: "auto" }}
		>
			<Stack w="100%" gap={3}>
				<Heading size="lg" fontWeight="bold" mt="30px">
					Profile
				</Heading>
				<Text>Company information is where job requests will be sent.</Text>
				<Image
					src={
						userPlus.partner === "HPV"
							? "assets/hpv_house_landscape.png"
							: "/assets/house_landscape.png"
					}
					alt="vendor house"
					m="auto"
				/>
				<FormControl isInvalid={formError && vendorData.name === ""}>
					<FormLabel>Company Name</FormLabel>
					<Input
						bgColor="white"
						type="text"
						name="name"
						placeholder="Enter your company name"
						value={vendorData.name}
						onChange={(e) => {
							handleChange("name", e.target.value)
						}}
					/>
					{formError && vendorData.name === "" && (
						<FormErrorMessage>Company Name is required.</FormErrorMessage>
					)}
				</FormControl>
				<FormControl isDisabled={userPlus.email}>
					<FormLabel>Company Email</FormLabel>
					<Input
						bgColor="white"
						type="text"
						name="companyEmail"
						placeholder="Enter your company contact email"
						value={vendorData.email}
						onChange={(e) => {
							handleChange("email", e.target.value.toLowerCase())
						}}
					/>
				</FormControl>
				<FormControl
					isInvalid={
						formError &&
						(vendorData.phoneNumber === "" ||
							(vendorData.phoneNumber &&
								!isValidPhoneNumber(vendorData.phoneNumber)))
					}
				>
					<FormLabel>Company Phone Number</FormLabel>
					<PhoneInput
						country="US"
						style={{
							width: "100%",
							height: "40px",
							padding: "0.5rem 0.75rem",
							border:
								formError &&
								(vendorData.phoneNumber === "" ||
									(vendorData.phoneNumber &&
										!isValidPhoneNumber(vendorData.phoneNumber)))
									? "1px solid #F05252"
									: "1px solid #CBD5E0",
							borderRadius: "0.375rem",
							outlineColor:
								formError &&
								(vendorData.phoneNumber === "" ||
									(vendorData.phoneNumber &&
										!isValidPhoneNumber(vendorData.phoneNumber)))
									? "#F05252"
									: "#CBD5E0",
						}}
						placeholder="Enter company phone number"
						value={vendorData.phoneNumber}
						onChange={(e) => {
							setVendorData((prevvendorData) => ({
								...prevvendorData,
								phoneNumber: e,
							}))
						}}
						onFocus={(e) => {
							e.target.style.borderColor = "#4299e1"
							e.target.style.boxShadow = "0 0 0 3px rgba(66, 153, 225, 0.1)"
							e.target.style.outlineColor = "#4299e1"
						}}
						onBlur={(e) => {
							e.target.style.borderColor =
								formError &&
								(vendorData.phoneNumber === "" ||
									(vendorData.phoneNumber &&
										!isValidPhoneNumber(vendorData.phoneNumber)))
									? "#F05252"
									: "#CBD5E0"
							e.target.style.boxShadow =
								formError &&
								(vendorData.phoneNumber === "" ||
									(vendorData.phoneNumber &&
										!isValidPhoneNumber(vendorData.phoneNumber)))
									? "0 0 0 1px #F05252"
									: "none"
						}}
					/>
					{formError &&
						(vendorData.phoneNumber === "" ||
							(vendorData.phoneNumber &&
								!isValidPhoneNumber(vendorData.phoneNumber))) && (
							<FormErrorMessage>
								Please enter a valid phone number.
							</FormErrorMessage>
						)}
				</FormControl>
				<FormControl>
					<FormLabel>Add your Google rating</FormLabel>
					<FormHelperText mb="10px" textDecoration="underline" color="blue.500">
						<a
							href="https://static1.squarespace.com/static/6527ef2eb30ef17a4fccc78a/t/65dbe86466b68c2f4bc9838e/1708910693264/Adding+Google+Ratings+to+Quiplo.pdf"
							target="_blank"
							rel="noopener noreferrer"
						>
							How to find your Google Place ID to share rating
						</a>
					</FormHelperText>
					<Input
						bgColor="white"
						type="tel"
						name="googlePlaceId"
						placeholder="Add Google Place ID"
						value={vendorData.googlePlaceId}
						onChange={(e) => {
							handleChange("googlePlaceId", e.target.value)
						}}
					/>
				</FormControl>
				{userPlus.partner && (
					<FormControl isDisabled={true}>
						<FormLabel>Calendly Link</FormLabel>
						<Input
							bgColor="white"
							type="text"
							name="calendlyLink"
							placeholder="Enter your calendly link"
							value={vendorData.calendlyLink}
							onChange={(e) => {
								handleChange("calendlyLink", e.target.value.toLowerCase())
							}}
						/>
					</FormControl>
				)}
				<Button
					w="100%"
					bgColor="borderColor"
					color="white"
					onClick={onSubmit}
					mt="30px"
					mb="40px"
				>
					Save
				</Button>
			</Stack>
		</Center>
	)
}
