import { Card, Image, Container, Heading, Text } from "@chakra-ui/react"

export const VendorCard = ({ isHPV }) => {
	return (
		<Card mt="20px" display="flex" flexDirection="row" maxW="xl" p="20px">
			<Image
				src={
					isHPV
						? process.env.PUBLIC_URL + "/assets/hpv_todo_icon.svg"
						: process.env.PUBLIC_URL + "/assets/todo_icon.svg"
				}
				alt="Computer Icon"
				w="65px"
				h="65px"
			/>
			<Container>
				<Heading size="md" fontWeight="bold" pb="5px">
					Organize preferred {isHPV ? "Home Pros" : "Vendors"} as a Real Estate
					Agent
				</Heading>
				<Text size="xs">
					Manage all of your trusted {isHPV ? "home pros" : "vendors"}, save
					scheduling time, and track all job requests in one place.
				</Text>
			</Container>
		</Card>
	)
}
