const hostname = window.location.hostname

const config = {
	development: {
		baseURL: "http://localhost:3001",
	},
	production: {
		baseURL: hostname.includes("quiploteams.com")
			? "https://app.quiploteams.com"
			: "https://app.homeprovalet.com",
	},
}

module.exports = config[process.env.NODE_ENV || "development"]
