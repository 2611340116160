import { useState, useEffect, useContext } from "react"
import { db } from "../firebase"
import { collection, getDocs, query, where } from "firebase/firestore"
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Button,
	Center,
	FormControl,
	FormLabel,
	FormErrorMessage,
	FormHelperText,
	HStack,
	IconButton,
	Image,
	Input,
	RadioGroup,
	Radio,
	Stack,
	Text,
	Textarea,
} from "@chakra-ui/react"
import { SmallCloseIcon } from "@chakra-ui/icons"
import { Select } from "chakra-react-select"
import DatePicker from "react-datepicker"
import PhoneInput from "react-phone-number-input/input"
import { isValidPhoneNumber } from "react-phone-number-input"
import { ReactMultiEmail } from "react-multi-email"

import { serviceTypes } from "../Options/ServiceTypes"
import { HPVServicesAndFees } from "../Options/HPVServicesAndFees"
import { UserContext } from "../Contexts/UserContext"
import { formatPhoneNumber } from "../Utilities"

import "react-multi-email/dist/style.css"
import "../email-input.css"
import "../select.css"
import "react-datepicker/dist/react-datepicker.css"
import "../date-picker.css"

export const JobRequestServiceDetails = ({
	dateTimes,
	formData,
	formError,
	handleAddServiceRequest,
	handleChange,
	handleGenericSelectChange,
	handleServiceTimeRemoval,
	handleVendorPhoneInviteRemoval,
	editingIndex,
	nextStep,
	previousStep,
	onSubmit,
	serviceRequests,
	setFormData,
	setFormError,
	setDateTimes,
}) => {
	const { userPlus } = useContext(UserContext)

	const [vendorData, setVendorData] = useState({
		companyName: null,
		phoneNumber: "",
	})
	const [services, setServices] = useState([])
	const [focused, setFocused] = useState(false)
	const [hpvSubServiceOptions, setHpvSubServiceOptions] = useState([])

	useEffect(() => {
		if (formData.serviceType.length > 0) {
			const getVendorList = async () => {
				let agentQuery
				let teamQuery
				if (userPlus.userType === "agent") {
					agentQuery = query(
						collection(db, "agentVendor"),
						where("agentId", "==", userPlus.id)
					)
				}
				if (userPlus.teamId && userPlus.userType === "agent") {
					teamQuery = query(
						collection(db, "agentVendor"),
						where("teamId", "==", userPlus.teamId)
					)
				}
				const queries = []
				if (agentQuery) {
					queries.push(getDocs(agentQuery))
				}
				if (teamQuery) {
					queries.push(getDocs(teamQuery))
				}

				const [agentDocs, teamDocs] = await Promise.all(queries)

				const agentData =
					agentDocs.docs.length > 0
						? agentDocs.docs.map((doc) => doc.data())
						: []
				const teamData =
					teamDocs && teamDocs.docs.length > 0
						? teamDocs.docs.map((doc) => doc.data())
						: []

				const vendors = [...agentData, ...teamData]
				const vendorIdList =
					vendors && vendors.length > 0
						? [...new Set(vendors.map((doc) => doc.vendorId))]
						: []
				if (vendorIdList.length > 0) {
					const chunkSize = 30
					const vendorIdChunks = []
					for (let i = 0; i < vendorIdList.length; i += chunkSize) {
						vendorIdChunks.push(vendorIdList.slice(i, i + chunkSize))
					}

					const services = []
					for (const vendorIdChunk of vendorIdChunks) {
						const servicesQuery = query(
							collection(db, "services"),
							where("userId", "in", vendorIdChunk),
							where("serviceType", "==", formData.serviceType)
						)
						const doc = await getDocs(servicesQuery)
						services.push(
							...doc.docs.map((doc) => ({
								id: doc.data().id,
								...doc.data(),
							}))
						)
					}

					setServices(services)
				}
			}
			const getClientVendorList = async () => {
				const agentQuery = query(
					collection(db, "userPreferredServices"),
					where(
						"userId",
						"==",
						userPlus.userType === "TC"
							? formData.teamMemberId.value
							: userPlus.invitedBy
					)
				)

				const teamQuery = query(
					collection(db, "teamPreferredServices"),
					where("teamId", "==", userPlus.teamId)
				)

				const agentServices = await getDocs(agentQuery)
				const teamServices = await getDocs(teamQuery)
				const agentVendorList = agentServices.docs.map(
					(doc) => doc.data().vendorId
				)
				const teamVendorList = teamServices.docs.map(
					(doc) => doc.data().vendorId
				)

				let vendorList = agentVendorList.concat(teamVendorList)
				vendorList = vendorList.filter((vendorId) => vendorId != null)
				if (vendorList.length > 0) {
					const uniqueVendors = Array.from(
						new Set(vendorList.map((vendor) => vendor))
					).map((id) => vendorList.find((vendor) => vendor === id))

					if (uniqueVendors.length > 0) {
						const chunkSize = 30
						const vendorIdChunks = []
						for (let i = 0; i < uniqueVendors.length; i += chunkSize) {
							vendorIdChunks.push(uniqueVendors.slice(i, i + chunkSize))
						}

						const services = []
						for (const vendorIdChunk of vendorIdChunks) {
							const servicesQuery = query(
								collection(db, "services"),
								where("userId", "in", vendorIdChunk),
								where("serviceType", "==", formData.serviceType)
							)
							const doc = await getDocs(servicesQuery)
							services.push(
								...doc.docs.map((doc) => ({
									id: doc.data().id,
									...doc.data(),
								}))
							)
						}

						setServices(services)
					}
				}
			}
			if (userPlus.userType === "agent") {
				getVendorList()
			} else {
				getClientVendorList()
			}
		}
	}, [formData.serviceType, userPlus])

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setDateTimes({ ...dateTimes, [name]: value })
	}

	const handlePhoneInviteChange = (event) => {
		setVendorData((prevVendorData) => ({
			...prevVendorData,
			[event.target.name]: event.target.value,
		}))
	}
	const handleDateChange = (date) => {
		setFormError(false)
		setDateTimes({ ...dateTimes, date })
	}

	const handleAddVendorPhone = () => {
		if (isValidPhoneNumber(vendorData.phoneNumber)) {
			handleChange("vendorInvitePhoneNumbers", [
				...formData.vendorInvitePhoneNumbers,
				vendorData,
			])
			setVendorData({ companyName: "", phoneNumber: "" })
		} else {
			alert("Please enter a valid phone number.")
		}
	}

	const formatTime = (timeString) => {
		const [hourString, minute] = timeString.split(":")
		const hour = +hourString % 24
		return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM")
	}

	const handleVendorChange = (selectedOptions, name) => {
		if (Array.isArray(selectedOptions)) {
			setFormData({
				...formData,
				vendorIds: selectedOptions.map((option) => option),
			})
		} else {
			setFormData({
				...formData,
				vendorIds: selectedOptions,
			})
		}
	}

	const servicePreferredDateError =
		formError &&
		(formData.servicePreferredDates.length === 0 ||
			(dateTimes.date !== null &&
				(dateTimes.startTime === "" || dateTimes.endTime === "")) ||
			(dateTimes.startTime !== "" &&
				(dateTimes.date === null || dateTimes.endTime === "")) ||
			(dateTimes.endTime !== "" &&
				(dateTimes.date === null || dateTimes.startTime === "")))

	const serviceTypesOptions = serviceTypes.map((s) => {
		return { label: s.service, value: s.service }
	})

	const HPVPrimaryServiceOptions = HPVServicesAndFees.map((s) => {
		return { label: s.primary_service, value: s.primary_service }
	})

	useEffect(() => {
		if (formData.serviceType.length > 0 && userPlus.partner === "HPV") {
			const selectedServiceDetails = HPVServicesAndFees.find(
				(service) => service.primary_service === formData.serviceType
			)

			// Check if the selected service has sub_services
			if (selectedServiceDetails && selectedServiceDetails.sub_services) {
				const subServiceOptions = selectedServiceDetails.sub_services.map(
					(s) => {
						return { label: s.name, value: s.name }
					}
				)
				setHpvSubServiceOptions(subServiceOptions)
			}
		}
	}, [formData.serviceType, userPlus.partner])

	const handleReviewRequests = async (e) => {
		e.preventDefault()
		await handleAddServiceRequest(e)
		if ((await handleAddServiceRequest(e)) !== "error") {
			nextStep()
		}
	}

	const handleAddEntry = async () => {
		if (dateTimes.date && dateTimes.startTime && dateTimes.endTime) {
			await handleChange("servicePreferredDates", [
				...formData.servicePreferredDates,
				dateTimes,
			])
			setDateTimes({ date: null, startTime: "", endTime: "" })
		} else {
			alert("Please fill in all fields.")
		}
	}

	const vendorOptions =
		services &&
		services.map((service) => {
			return { label: service.name, value: service.userId }
		})

	return (
		<>
			<Center
				flexDirection="column"
				maxW="629px"
				margin={{ base: "15px", md: "auto" }}
				gap={3}
			>
				<FormControl isInvalid={formError && formData.serviceType.length === 0}>
					<FormLabel>Service Type</FormLabel>
					<Select
						options={
							userPlus.partner === "HPV"
								? HPVPrimaryServiceOptions
								: serviceTypesOptions
						}
						value={
							formData.serviceType.length > 0 &&
							serviceTypesOptions.find((option) =>
								formData.serviceType.includes(option.value)
							)
						}
						onChange={(e) => handleGenericSelectChange(e, "serviceType")}
					/>
					{formError && formData.serviceType.length === 0 && (
						<FormErrorMessage>A service type is required.</FormErrorMessage>
					)}
				</FormControl>
				{hpvSubServiceOptions.length > 0 && userPlus.partner === "HPV" && (
					<FormControl
						isInvalid={formError && formData.serviceType.length === 0}
					>
						<FormLabel>Select a Service Detail</FormLabel>
						<Select
							options={hpvSubServiceOptions}
							value={
								formData.subServiceType.length > 0 &&
								hpvSubServiceOptions.find((option) =>
									formData.subServiceType.includes(option.value)
								)
							}
							onChange={(e) => handleGenericSelectChange(e, "subServiceType")}
						/>
						{formError && formData.serviceType.length === 0 && (
							<FormErrorMessage>A service type is required.</FormErrorMessage>
						)}
					</FormControl>
				)}
				<FormControl
					isInvalid={
						formError &&
						(formData.vendorIds.length + formData.vendorInviteEmails.length >
							3 ||
							formData.vendorIds.length + formData.vendorInviteEmails.length ===
								0)
					}
					isDisabled={formData.serviceType.length === 0}
				>
					<FormLabel>{userPlus.partner ? "Home Pros" : "Vendors"}</FormLabel>
					<Select
						isMulti
						options={vendorOptions}
						value={
							formData.vendorIds.length > 0
								? vendorOptions.find((option) =>
										formData.vendorIds.includes(option.value)
								  )
								: null
						}
						onChange={(e) => handleVendorChange(e, "vendorIds")}
					/>
					{formError &&
						formData.vendorIds.length + formData.vendorInviteEmails.length ===
							0 && <FormErrorMessage>A vendor is required.</FormErrorMessage>}
					{formError &&
						formData.vendorIds.length + formData.vendorInviteEmails.length >
							3 && (
							<FormErrorMessage>
								There is a maximum of 3 vendors per service request.
							</FormErrorMessage>
						)}
				</FormControl>
				{(userPlus.userType === "agent" || userPlus.userType === "TC") &&
					!userPlus.partner && (
						<>
							<FormLabel w="100%">Invite Vendors </FormLabel>
							<Accordion allowMultiple w="100%">
								<AccordionItem bgColor="white">
									<h2>
										<AccordionButton>
											<Box display="flex" flex="1" textAlign="left">
												<Image src="/assets/chat.svg" />
												<Text my="auto" ml="10px">
													Message
												</Text>
											</Box>
											<AccordionIcon />
										</AccordionButton>
									</h2>
									<AccordionPanel pb={4}>
										<FormControl>
											<Input
												bgColor="white"
												type="text"
												name="companyName"
												placeholder="Enter Company Name"
												value={vendorData.companyName}
												onChange={handlePhoneInviteChange}
											/>
										</FormControl>
										<Box w="100%" display="flex" mt="10px">
											<FormControl mr="15px">
												<PhoneInput
													country="US"
													style={{
														width: "100%",
														height: "40px",
														padding: "0.5rem 0.75rem",
														border: "1px solid #CBD5E0",
														borderRadius: "0.375rem",
													}}
													placeholder="Enter company phone number"
													value={vendorData.phoneNumber}
													onChange={(e) => {
														setVendorData((prevVendorData) => ({
															...prevVendorData,
															phoneNumber: e,
														}))
													}}
													onFocus={(e) => {
														e.target.style.borderColor = "#4299e1"
														e.target.style.boxShadow =
															"0 0 0 3px rgba(66, 153, 225, 0.1)"
													}}
													onBlur={(e) => {
														e.target.style.borderColor =
															formError &&
															!isValidPhoneNumber(vendorData.phoneNumber)
																? "#F05252"
																: "#CBD5E0"
														e.target.style.boxShadow =
															formError &&
															!isValidPhoneNumber(vendorData.phoneNumber)
																? "0 0 0 1px #F05252"
																: "none"
													}}
												/>
											</FormControl>
											<Button
												w="60px"
												bgColor="darkGray"
												borderRadius="5px"
												onClick={() => handleAddVendorPhone()}
											>
												Add
											</Button>
										</Box>
										<Box mt="15px">
											{formData.vendorInvitePhoneNumbers.map((entry, index) => (
												<HStack gap={5} mb="5px" key={index}>
													<Text key={index}>
														{formatPhoneNumber(entry.phoneNumber)}
													</Text>
													<IconButton
														bgColor="background"
														icon={<SmallCloseIcon />}
														onClick={(index) =>
															handleVendorPhoneInviteRemoval(index)
														}
													/>
												</HStack>
											))}
										</Box>
									</AccordionPanel>
								</AccordionItem>
								<AccordionItem bgColor="white">
									<h2>
										<AccordionButton>
											<Box display="flex" flex="1" textAlign="left">
												<Image src="/assets/mail.svg" />
												<Text my="auto" ml="10px">
													Email
												</Text>
											</Box>
											<AccordionIcon />
										</AccordionButton>
									</h2>
									<AccordionPanel pb={4}>
										<FormControl
											isInvalid={formError && vendorData.companyEmail === ""}
										>
											<ReactMultiEmail
												placeholder="Enter email address"
												emails={formData.vendorInviteEmails}
												onChange={(_emails: string[]) => {
													handleChange("vendorInviteEmails", _emails)
												}}
												autoFocus={true}
												onFocus={() => setFocused(true)}
												onBlur={() => setFocused(false)}
												getLabel={(email, index, removeEmail) => {
													return (
														<div data-tag key={index}>
															<div data-tag-item>{email}</div>
															<span
																data-tag-handle
																onClick={() => removeEmail(index)}
															>
																×
															</span>
														</div>
													)
												}}
											/>

											<FormHelperText mb="5px">
												You can paste multiple emails at once.
											</FormHelperText>
										</FormControl>
									</AccordionPanel>
								</AccordionItem>
							</Accordion>
						</>
					)}
				<FormControl>
					<FormLabel>Job Request Details</FormLabel>
					<Textarea
						name="jobRequestDetails"
						border="1px solid #E2E2EA"
						bgColor="white"
						value={formData.jobRequestDetails}
						onChange={(e) => {
							handleChange("jobRequestDetails", e.target.value)
						}}
						placeholder="Enter job request details"
					/>
				</FormControl>
				<FormControl isInvalid={formError && formData.timePreference === null}>
					<FormLabel>Select your Scheduling Preference</FormLabel>
					<RadioGroup
						name="timePreference"
						value={formData.timePreference || ""}
						onChange={(value) => {
							handleChange("timePreference", value)
						}}
					>
						<Stack direction="column">
							<Radio value="startWork">I'm ready to hire</Radio>
							<Radio value="getEstimates">I'm browsing for estimates</Radio>
						</Stack>
					</RadioGroup>
					{formError && formData.timePreference === null && (
						<FormErrorMessage>
							Scheduling Preference is required.
						</FormErrorMessage>
					)}
				</FormControl>
				<FormControl isInvalid={servicePreferredDateError}>
					<FormLabel>Preferred Times</FormLabel>
					<DatePicker
						w="100%"
						h="40px"
						border-radius="md"
						border={
							servicePreferredDateError ? "1px solid red" : "1px solid #e2e2ea"
						}
						selected={dateTimes.date}
						onChange={handleDateChange}
						placeholderText="Select date"
						dateFormat="MM/dd/yyyy"
					/>
					{servicePreferredDateError && (
						<FormErrorMessage>Preferred date required.</FormErrorMessage>
					)}
				</FormControl>
				<HStack my="15px" gap={2} w="100%">
					<FormControl isInvalid={servicePreferredDateError}>
						<Input
							border={
								servicePreferredDateError
									? "1px solid red"
									: "1px solid #e2e2ea"
							}
							type="time"
							name="startTime"
							value={dateTimes.startTime}
							variant="jobRequest"
							onChange={handleInputChange}
						/>
						{servicePreferredDateError ? (
							<FormErrorMessage>
								Preferred start time is required.
							</FormErrorMessage>
						) : null}
					</FormControl>

					<FormControl isInvalid={servicePreferredDateError}>
						<Input
							border={
								servicePreferredDateError
									? "1px solid red"
									: "1px solid #e2e2ea"
							}
							type="time"
							name="endTime"
							value={dateTimes.endTime}
							onChange={handleInputChange}
							variant="jobRequest"
						/>
						{servicePreferredDateError && (
							<FormErrorMessage>Preferred end time required.</FormErrorMessage>
						)}
					</FormControl>
				</HStack>
			</Center>
			<Box maxW="629px" margin={{ base: "15px", md: "auto" }}>
				<Button onClick={handleAddEntry}>Add Time Slot</Button>
				<Box mt="15px">
					{formData.servicePreferredDates.map((entry, index) => (
						<HStack gap={5} mb="5px" key={index}>
							<Text key={index}>{entry.date.toLocaleDateString("en-US")}</Text>
							<Text>
								{formatTime(entry.startTime)} - {formatTime(entry.endTime)}
							</Text>
							<IconButton
								bgColor="background"
								icon={<SmallCloseIcon />}
								onClick={(index) => handleServiceTimeRemoval(index)}
							/>
						</HStack>
					))}
				</Box>
			</Box>
			<Center
				flexDirection="column"
				maxW="629px"
				margin={{ base: "15px", md: "auto" }}
				gap={3}
			>
				{editingIndex !== null && (
					<Button
						onClick={handleAddServiceRequest}
						w="100%"
						mt="10px"
						mb="40px"
					>
						Edit Service Request
					</Button>
				)}
				{serviceRequests.length > 0 && editingIndex === null && (
					<>
						<Button
							onClick={handleReviewRequests}
							w="100%"
							bgColor="borderColor"
							color="white"
							mt="40px"
						>
							Review Job Requests
						</Button>
						<Button
							onClick={handleAddServiceRequest}
							w="100%"
							mt="10px"
							mb="40px"
						>
							Add Another Service
						</Button>
					</>
				)}{" "}
				{editingIndex === null && serviceRequests.length === 0 && (
					<>
						<Button
							onClick={onSubmit}
							w="100%"
							bgColor="borderColor"
							color="white"
							mt="40px"
						>
							Submit Job Request
						</Button>
						<Button onClick={handleAddServiceRequest} w="100%" mt="10px">
							Add Another Service
						</Button>
						<Button onClick={previousStep} w="100%" mt="10px" mb="40px">
							Back
						</Button>
					</>
				)}
			</Center>
		</>
	)
}
