export const HPVServicesAndFees = [
	{
		primary_service: "Air Duct Cleaning",
		sub_services: [
			{
				name: "Residential Ducts",
				lead_fee: 25,
			},
			{
				name: "Dryer Vent Cleaning",
				lead_fee: 15,
			},
			{
				name: "Mold Treatment in Ducts",
				lead_fee: 60,
			},
		],
	},
	{
		primary_service: "Appliance Installation",
		sub_services: [
			{
				name: "Refrigerator",
				lead_fee: 25,
			},
			{
				name: "Dishwasher",
				lead_fee: 25,
			},
			{
				name: "Washing Machine",
				lead_fee: 25,
			},
			{
				name: "Dryer",
				lead_fee: 25,
			},
			{
				name: "Oven",
				lead_fee: 25,
			},
			{
				name: "Cooktop",
				lead_fee: 25,
			},
			{
				name: "Range Hood",
				lead_fee: 25,
			},
		],
	},
	{
		primary_service: "Appliance Repair",
		sub_services: [
			{
				name: "Refrigerator",
				lead_fee: 25,
			},
			{
				name: "Washer/Dryer",
				lead_fee: 25,
			},
			{
				name: "Microwave",
				lead_fee: 25,
			},
			{
				name: "Dishwasher",
				lead_fee: 25,
			},
			{
				name: "Cooktop",
				lead_fee: 25,
			},
			{
				name: "Oven",
				lead_fee: 25,
			},
		],
	},
	{
		primary_service: "Asbestos Removal",
		sub_services: [
			{
				name: "Testing",
				lead_fee: 35,
			},
			{
				name: "Removal",
				lead_fee: 75,
			},
			{
				name: "Encapsulation",
				lead_fee: 90,
			},
			{
				name: "Site Cleanup",
				lead_fee: 50,
			},
		],
	},
	{
		primary_service: "Awnings",
		sub_services: [
			{
				name: "Installation",
				lead_fee: 50,
			},
			{
				name: "Repair",
				lead_fee: 25,
			},
			{
				name: "Retractable Awnings",
				lead_fee: 125,
			},
			{
				name: "Fixed Awnings",
				lead_fee: 100,
			},
		],
	},
	{
		primary_service: "Basement Remodel",
		sub_services: [
			{
				name: "Waterproofing",
				lead_fee: 75,
			},
			{
				name: "Finishing",
				lead_fee: 75,
			},
			{
				name: "Egress Windows",
				lead_fee: 75,
			},
			{
				name: "Floor Installation",
				lead_fee: 125,
			},
			{
				name: "Full Remodel",
				lead_fee: 450,
			},
			{
				name: "Change Floor Plan",
				lead_fee: 250,
			},
		],
	},
	{
		primary_service: "Bathroom Remodel",
		sub_services: [
			{
				name: "Shower Install",
				lead_fee: 200,
			},
			{
				name: "Vanity Replacement",
				lead_fee: 50,
			},
			{
				name: "Tile Work",
				lead_fee: 140,
			},
			{
				name: "Lighting",
				lead_fee: 75,
			},
			{
				name: "Full Remodel",
				lead_fee: 275,
			},
			{
				name: "Change Floor Plan",
				lead_fee: 210,
			},
		],
	},
	{
		primary_service: "Bathtub Refinishing",
		sub_services: [
			{
				name: "Reglazing",
				lead_fee: 75,
			},
			{
				name: "Scratch Repair",
				lead_fee: 25,
			},
			{
				name: "Resurfacing",
				lead_fee: 75,
			},
		],
	},
	{
		primary_service: "Cabinet Replacement & Refacing",
		sub_services: [
			{
				name: "Cabinet Replacement",
				lead_fee: 125,
			},
			{
				name: "Refacing",
				lead_fee: 175,
			},
			{
				name: "Refinishing",
				lead_fee: 175,
			},
			{
				name: "Custom Cabinet Installation",
				lead_fee: 150,
			},
		],
	},
	{
		primary_service: "Carpentry",
		sub_services: [
			{
				name: "Custom Woodwork",
				lead_fee: 150,
			},
			{
				name: "Trim and Molding",
				lead_fee: 125,
			},
			{
				name: "Shelving",
				lead_fee: 125,
			},
			{
				name: "Furniture Repair",
				lead_fee: 75,
			},
			{
				name: "Install Doors",
				lead_fee: 75,
			},
			{
				name: "Door Repair",
				lead_fee: 25,
			},
		],
	},
	{
		primary_service: "Carpet Cleaning",
		sub_services: [
			{
				name: "Deep Cleaning",
				lead_fee: 50,
			},
			{
				name: "Stain Removal",
				lead_fee: 25,
			},
			{
				name: "Odor Treatment",
				lead_fee: 25,
			},
			{
				name: "Area Rugs",
				lead_fee: 40,
			},
		],
	},
	{
		primary_service: "Carpet Installation",
		sub_services: [
			{
				name: "Wall-to-Wall Carpet",
				lead_fee: 150,
			},
			{
				name: "Carpet Tiles",
				lead_fee: 150,
			},
			{
				name: "Removal and Disposal",
				lead_fee: 25,
			},
			{
				name: "Padding Install",
				lead_fee: 25,
			},
		],
	},
	{
		primary_service: "Chimney Cap Repair",
		sub_services: [
			{
				name: "Installation",
				lead_fee: 50,
			},
			{
				name: "Cap Replacement",
				lead_fee: 25,
			},
			{
				name: "Cap Seal",
				lead_fee: 25,
			},
		],
	},
	{
		primary_service: "Chimney Repair",
		sub_services: [
			{
				name: "Masonry Repair",
				lead_fee: 50,
			},
			{
				name: "Flue Replacement",
				lead_fee: 125,
			},
			{
				name: "Smoke Chamber Repair",
				lead_fee: 50,
			},
		],
	},
	{
		primary_service: "Chimney Sweep",
		sub_services: [
			{
				name: "Basic Cleaning",
				lead_fee: 50,
			},
			{
				name: "Creosote Removal",
				lead_fee: 50,
			},
			{
				name: "Bird Nest Removal",
				lead_fee: 25,
			},
		],
	},
	{
		primary_service: "Closet Design",
		sub_services: [
			{
				name: "Walk-In Closet",
				lead_fee: 150,
			},
			{
				name: "Reach-In Closet",
				lead_fee: 50,
			},
			{
				name: "Custom Shelving",
				lead_fee: 75,
			},
			{
				name: "Organizing Solutions",
				lead_fee: 95,
			},
		],
	},
	{
		primary_service: "Concrete Driveways",
		sub_services: [
			{
				name: "New Driveway Install",
				lead_fee: 200,
			},
			{
				name: "Driveway Resurfacing",
				lead_fee: 125,
			},
			{
				name: "Sealing",
				lead_fee: 75,
			},
		],
	},
	{
		primary_service: "Concrete Repair",
		sub_services: [
			{
				name: "Crack Repair",
				lead_fee: 50,
			},
			{
				name: "Foundation Stabilization",
				lead_fee: 50,
			},
			{
				name: "Concrete Lifting",
				lead_fee: 125,
			},
		],
	},
	{
		primary_service: "Countertop Installation",
		sub_services: [
			{
				name: "Granite",
				lead_fee: 125,
			},
			{
				name: "Marble",
				lead_fee: 150,
			},
			{
				name: "Quartz",
				lead_fee: 150,
			},
			{
				name: "Butcher Block",
				lead_fee: 125,
			},
			{
				name: "Concrete",
				lead_fee: 100,
			},
		],
	},
	{
		primary_service: "Crawl Space Encapsulation",
		sub_services: [
			{
				name: "Moisture Barrier",
				lead_fee: 75,
			},
			{
				name: "Insulation",
				lead_fee: 125,
			},
			{
				name: "Sealing",
				lead_fee: 175,
			},
			{
				name: "Ventilation System Install",
				lead_fee: 150,
			},
		],
	},
	{
		primary_service: "Crawl Space Repair",
		sub_services: [
			{
				name: "Structural Reinforcement",
				lead_fee: 150,
			},
			{
				name: "Waterproofing",
				lead_fee: 150,
			},
			{
				name: "Mold Removal",
				lead_fee: 150,
			},
		],
	},
	{
		primary_service: "Decks & Porches Install & Repair",
		sub_services: [
			{
				name: "New Build",
				lead_fee: 225,
			},
			{
				name: "Repair",
				lead_fee: 50,
			},
			{
				name: "Railing Installation",
				lead_fee: 75,
			},
			{
				name: "Stairs",
				lead_fee: 75,
			},
		],
	},
	{
		primary_service: "Demolition",
		sub_services: [
			{
				name: "Interior Demolition",
				lead_fee: 75,
			},
			{
				name: "Exterior Demolition",
				lead_fee: 75,
			},
			{
				name: "Site Clearing",
				lead_fee: 75,
			},
		],
	},
	{
		primary_service: "Drain Cleaning",
		sub_services: [
			{
				name: "Main Sewer Line",
				lead_fee: 125,
			},
			{
				name: "Kitchen Drain",
				lead_fee: 25,
			},
			{
				name: "Bathroom Drain",
				lead_fee: 25,
			},
			{
				name: "Outdoor Drain",
				lead_fee: 25,
			},
		],
	},
	{
		primary_service: "Drain Pipe Installation",
		sub_services: [
			{
				name: "French Drain",
				lead_fee: 75,
			},
			{
				name: "Downspout Drainage",
				lead_fee: 75,
			},
			{
				name: "Sewer Line Install",
				lead_fee: 125,
			},
		],
	},
	{
		primary_service: "Drain Pipe Repair",
		sub_services: [
			{
				name: "French Drain",
				lead_fee: 25,
			},
			{
				name: "Downspout Drainage",
				lead_fee: 25,
			},
			{
				name: "Sewer Line Repair",
				lead_fee: 25,
			},
		],
	},
	{
		primary_service: "Driveway Gate Installation",
		sub_services: [
			{
				name: "Automated Gates",
				lead_fee: 250,
			},
			{
				name: "Manual Gates",
				lead_fee: 125,
			},
			{
				name: "Gate Opener Repair",
				lead_fee: 50,
			},
		],
	},
	{
		primary_service: "Driveway Pavers",
		sub_services: [
			{
				name: "Concrete Pavers",
				lead_fee: 125,
			},
			{
				name: "Stone Pavers",
				lead_fee: 125,
			},
			{
				name: "Paver Repairs",
				lead_fee: 50,
			},
		],
	},
	{
		primary_service: "Drywall Installation & Repair",
		sub_services: [
			{
				name: "New Drywall",
				lead_fee: 125,
			},
			{
				name: "Patch Repair",
				lead_fee: 25,
			},
			{
				name: "Texture Repair",
				lead_fee: 25,
			},
		],
	},
	{
		primary_service: "Electrical Inspection",
		lead_fee: 50,
	},
	{
		primary_service: "Electrician",
		sub_services: [
			{
				name: "Change Outlets",
				lead_fee: 25,
			},
			{
				name: "Move Outlets",
				lead_fee: 25,
			},
			{
				name: "Install Electric Car Charger",
				lead_fee: 30,
			},
			{
				name: "Generator",
				lead_fee: 175,
			},
			{
				name: "Pools / Hot Tubs",
				lead_fee: 85,
			},
			{
				name: "General Service Call",
				lead_fee: 25,
			},
			{
				name: "Troubleshooting",
				lead_fee: 25,
			},
			{
				name: "Fan / Light Replacement",
				lead_fee: 25,
			},
			{
				name: "Landscape Lights",
				lead_fee: 50,
			},
			{
				name: "Sub Panels",
				lead_fee: 50,
			},
			{
				name: "Smoke Detectors",
				lead_fee: 25,
			},
			{
				name: "Exhaust Fans",
				lead_fee: 25,
			},
			{
				name: "Outdoor Lighting",
				lead_fee: 40,
			},
			{
				name: "Install Security System (video doorbell)",
				lead_fee: 25,
			},
			{
				name: "General Remodeling",
				lead_fee: 65,
			},
			{
				name: "Restore Power",
				lead_fee: 25,
			},
			{
				name: "Panel Upgrades",
				lead_fee: 50,
			},
			{
				name: "Circuit Breaker Replacement",
				lead_fee: 25,
			},
			{
				name: "Whole Home Wiring",
				lead_fee: 225,
			},
			{
				name: "Change Outlets",
				lead_fee: 25,
			},
		],
	},
	{
		primary_service: "Epoxy Flooring",
		sub_services: [
			{
				name: "Garage Floor",
				lead_fee: 125,
			},
			{
				name: "Basement Floor",
				lead_fee: 150,
			},
		],
	},
	{
		primary_service: "Excavating",
		sub_services: [
			{
				name: "Land Clearing",
				lead_fee: 125,
			},
			{
				name: "Grading",
				lead_fee: 200,
			},
			{
				name: "Site Prep",
				lead_fee: 150,
			},
		],
	},
	{
		primary_service: "Exterior Painting",
		sub_services: [
			{
				name: "Siding Painting",
				lead_fee: 250,
			},
			{
				name: "Trim Painting",
				lead_fee: 125,
			},
			{
				name: "Shutter Painting",
				lead_fee: 75,
			},
		],
	},
	{
		primary_service: "Fencing",
		sub_services: [
			{
				name: "Wood Fence",
				lead_fee: 150,
			},
			{
				name: "Vinyl Fence",
				lead_fee: 150,
			},
			{
				name: "Metal Fence",
				lead_fee: 75,
			},
			{
				name: "Gate Installation",
				lead_fee: 100,
			},
		],
	},
	{
		primary_service: "Floor Buffing",
		sub_services: [
			{
				name: "Hardwood Buffing",
				lead_fee: 125,
			},
			{
				name: "Tile Buffing",
				lead_fee: 75,
			},
			{
				name: "Concrete Polishing",
				lead_fee: 75,
			},
		],
	},
	{
		primary_service: "Floor Cleaning",
		sub_services: [
			{
				name: "Tile and Grout",
				lead_fee: 75,
			},
			{
				name: "Hardwood",
				lead_fee: 50,
			},
			{
				name: "Carpet",
				lead_fee: 40,
			},
			{
				name: "Commercial Floor Cleaning",
				lead_fee: 50,
			},
		],
	},
	{
		primary_service: "Foundation Inspection",
		lead_fee: 25,
	},
	{
		primary_service: "Foundation Repair",
		sub_services: [
			{
				name: "Crack Injection",
				lead_fee: 50,
			},
			{
				name: "Pier Installation",
				lead_fee: 125,
			},
			{
				name: "Wall Reinforcement",
				lead_fee: 125,
			},
		],
	},
	{
		primary_service: "Furniture Refinishing",
		sub_services: [
			{
				name: "Stain/Finish Repair",
				lead_fee: 50,
			},
			{
				name: "Upholstery",
				lead_fee: 50,
			},
			{
				name: "Painting",
				lead_fee: 50,
			},
			{
				name: "Restoration",
				lead_fee: 75,
			},
		],
	},
	{
		primary_service: "Garage Building",
		sub_services: [
			{
				name: "Attached Garage",
				lead_fee: 450,
			},
			{
				name: "Detached Garage",
				lead_fee: 450,
			},
			{
				name: "Concrete Floor Installation",
				lead_fee: 200,
			},
		],
	},
	{
		primary_service: "Garage Door Installation & Repair",
		sub_services: [
			{
				name: "New Door Installation",
				lead_fee: 100,
			},
			{
				name: "Track Repair",
				lead_fee: 40,
			},
			{
				name: "Opener Installation/Repair",
				lead_fee: 40,
			},
		],
	},
	{
		primary_service: "Gas Leak Repair",
		sub_services: [
			{
				name: "Gas Line Repair",
				lead_fee: 150,
			},
			{
				name: "Leak Detection",
				lead_fee: 75,
			},
			{
				name: "Valve Replacement",
				lead_fee: 25,
			},
		],
	},
	{
		primary_service: "General Contractor",
		sub_services: [
			{
				name: "Kitchen Remodel",
				lead_fee: 450,
			},
			{
				name: "Bathroom Remodel",
				lead_fee: 325,
			},
			{
				name: "Addition to Home",
				lead_fee: 450,
			},
			{
				name: "Basement Remodel",
				lead_fee: 450,
			},
			{
				name: "Multiple Room Renovation",
				lead_fee: 450,
			},
			{
				name: "Change Floor Plan",
				lead_fee: 450,
			},
		],
	},
	{
		primary_service: "General Inspection",
		lead_fee: 100,
	},
	{
		primary_service: "General Plumbing",
		sub_services: [
			{
				name: "Fixture Installation",
				lead_fee: 25,
			},
			{
				name: "Leak Repair",
				lead_fee: 25,
			},
			{
				name: "Drain Cleaning",
				lead_fee: 25,
			},
			{
				name: "Drain Installation",
				lead_fee: 25,
			},
		],
	},
	{
		primary_service: "Gutter Cleaning",
		lead_fee: 25,
	},
	{
		primary_service: "Gutter Installation",
		sub_services: [
			{
				name: "Seamless Gutters",
				lead_fee: 125,
			},
			{
				name: "Gutter Guards",
				lead_fee: 125,
			},
			{
				name: "Downspout Installation",
				lead_fee: 75,
			},
		],
	},
	{
		primary_service: "Gutter Repair",
		sub_services: [
			{
				name: "Leak Repair",
				lead_fee: 25,
			},
			{
				name: "Downspout Repair",
				lead_fee: 25,
			},
			{
				name: "Reattaching Gutters",
				lead_fee: 25,
			},
		],
	},
	{
		primary_service: "Handyman",
		sub_services: [
			{
				name: "Minor Repairs",
				lead_fee: 25,
			},
			{
				name: "Remodel Projects",
				lead_fee: 125,
			},
			{
				name: "Fixture Installation",
				lead_fee: 25,
			},
		],
	},
	{
		primary_service: "Hardscaping",
		sub_services: [
			{
				name: "Patios",
				lead_fee: 125,
			},
			{
				name: "Walkways",
				lead_fee: 100,
			},
			{
				name: "Retaining Walls",
				lead_fee: 125,
			},
		],
	},
	{
		primary_service: "Hardwood Floor Installation & Repair",
		sub_services: [
			{
				name: "Refinishing",
				lead_fee: 125,
			},
			{
				name: "Repair",
				lead_fee: 50,
			},
			{
				name: "New Installations",
				lead_fee: 220,
			},
		],
	},
	{
		primary_service: "Home Remodeling",
		sub_services: [
			{
				name: "Full Home Remodel",
				lead_fee: 450,
			},
			{
				name: "Addition",
				lead_fee: 450,
			},
			{
				name: "Design Services",
				lead_fee: 250,
			},
		],
	},
	{
		primary_service: "Home Staging",
		sub_services: [
			{
				name: "Furniture Rental",
				lead_fee: 75,
			},
			{
				name: "Interior Styling",
				lead_fee: 50,
			},
			{
				name: "Decluttering",
				lead_fee: 50,
			},
		],
	},
	{
		primary_service: "Hot Water Heater Installation & Repair",
		sub_services: [
			{
				name: "Tankless Water Heater",
				lead_fee: 150,
			},
			{
				name: "Standard Water Heater",
				lead_fee: 75,
			},
			{
				name: "Maintenance",
				lead_fee: 25,
			},
		],
	},
	{
		primary_service: "House Cleaning",
		sub_services: [
			{
				name: "Deep Cleaning",
				lead_fee: 25,
			},
			{
				name: "Move-In/Move-Out Cleaning",
				lead_fee: 25,
			},
			{
				name: "Recurring Cleaning",
				lead_fee: 50,
			},
			{
				name: "One Time Service",
				lead_fee: 75,
			},
		],
	},
	{
		primary_service: "HVAC Inspection",
		sub_services: [
			{
				name: "Annual Preventative Maintenance",
				lead_fee: 25,
			},
		],
	},
	{
		primary_service: "HVAC Installation & Repair",
		sub_services: [
			{
				name: "AC Installation",
				lead_fee: 200,
			},
			{
				name: "Cooling Not Working",
				lead_fee: 50,
			},
			{
				name: "Heating Not Working",
				lead_fee: 50,
			},
			{
				name: "Duct Repair",
				lead_fee: 50,
			},
		],
	},
	{
		primary_service: "Insulation Install",
		sub_services: [
			{
				name: "Attic Insulation",
				lead_fee: 80,
			},
			{
				name: "Wall Insulation",
				lead_fee: 50,
			},
			{
				name: "Spray Foam",
				lead_fee: 110,
			},
		],
	},
	{
		primary_service: "Interior Painting",
		sub_services: [
			{
				name: "Wall Painting",
				lead_fee: 150,
			},
			{
				name: "Cabinet Painting",
				lead_fee: 150,
			},
			{
				name: "Trim Painting",
				lead_fee: 75,
			},
		],
	},
	{
		primary_service: "Irrigation System",
		sub_services: [
			{
				name: "Installation",
				lead_fee: 150,
			},
			{
				name: "Repair",
				lead_fee: 25,
			},
			{
				name: "Seasonal Maintenance",
				lead_fee: 25,
			},
		],
	},
	{
		primary_service: "Kitchen Remodel",
		sub_services: [
			{
				name: "Cabinet Replacement",
				lead_fee: 200,
			},
			{
				name: "Backsplash",
				lead_fee: 75,
			},
			{
				name: "Countertop Replacement",
				lead_fee: 125,
			},
			{
				name: "Change Floor Plan",
				lead_fee: 200,
			},
		],
	},
	{
		primary_service: "Landscape Design",
		lead_fee: 75,
	},
	{
		primary_service: "Landscape Lighting",
		lead_fee: 75,
	},
	{
		primary_service: "Lawn Care",
		sub_services: [
			{
				name: "Mowing",
				lead_fee: 15,
			},
			{
				name: "Edging",
				lead_fee: 10,
			},
			{
				name: "Weed Control",
				lead_fee: 10,
			},
			{
				name: "Fertilization",
				lead_fee: 25,
			},
			{
				name: "Weekly Recurring",
				lead_fee: 125,
			},
			{
				name: "Bi-Monthly Recurring",
				lead_fee: 100,
			},
			{
				name: "Monthly Recurring",
				lead_fee: 75,
			},
		],
	},
	{
		primary_service: "Lawn Treatment",
		lead_fee: 50,
	},
	{
		primary_service: "Lead Paint Removal",
		sub_services: [
			{
				name: "Paint Stripping",
				lead_fee: 100,
			},
			{
				name: "Encapsulation",
				lead_fee: 125,
			},
			{
				name: "Surface Preparation",
				lead_fee: 50,
			},
			{
				name: "Disposal",
				lead_fee: 50,
			},
		],
	},
	{
		primary_service: "Lead-Based Paint Inspection",
		lead_fee: 25,
	},
	{
		primary_service: "Leaf Removal",
		sub_services: [
			{
				name: "Lawn Raking",
				lead_fee: 25,
			},
			{
				name: "Leaf Blowing",
				lead_fee: 25,
			},
			{
				name: "Bagging",
				lead_fee: 25,
			},
			{
				name: "Disposal",
				lead_fee: 25,
			},
		],
	},
	{
		primary_service: "Locksmith",
		sub_services: [
			{
				name: "Lock Installation",
				lead_fee: 25,
			},
			{
				name: "Rekeying",
				lead_fee: 25,
			},
			{
				name: "Lock Repair",
				lead_fee: 25,
			},
			{
				name: "Safe Opening",
				lead_fee: 40,
			},
			{
				name: "Key Duplication",
				lead_fee: 10,
			},
		],
	},
	{
		primary_service: "Masonry",
		sub_services: [
			{
				name: "Bricklaying",
				lead_fee: 75,
			},
			{
				name: "Stonework",
				lead_fee: 75,
			},
			{
				name: "Retaining Walls",
				lead_fee: 150,
			},
			{
				name: "Driveway Installation",
				lead_fee: 200,
			},
			{
				name: "Tuckpointing",
				lead_fee: 100,
			},
		],
	},
	{
		primary_service: "Measurements",
		sub_services: [
			{
				name: "Property Measurement",
				lead_fee: 25,
			},
			{
				name: "Interior Space Measurement",
				lead_fee: 25,
			},
			{
				name: "Floor Plans",
				lead_fee: 100,
			},
		],
	},
	{
		primary_service: "Mold Inspection",
		lead_fee: 25,
	},
	{
		primary_service: "Mold Remediation",
		sub_services: [
			{
				name: "Mold Removal",
				lead_fee: 50,
			},
			{
				name: "Dehumidification",
				lead_fee: 50,
			},
			{
				name: "Cleaning",
				lead_fee: 50,
			},
			{
				name: "Disinfecting",
				lead_fee: 50,
			},
		],
	},
	{
		primary_service: "Mudjacking",
		sub_services: [
			{
				name: "Foundation Leveling",
				lead_fee: null,
			},
			{
				name: "Concrete Raising",
				lead_fee: null,
			},
			{
				name: "Slab Lifting",
				lead_fee: null,
			},
			{
				name: "Sidewalk Leveling",
				lead_fee: null,
			},
		],
	},
	{
		primary_service: "Mulch Delivering",
		lead_fee: 50,
	},
	{
		primary_service: "Outdoor Drainage",
		sub_services: [
			{
				name: "French Drains",
				lead_fee: 75,
			},
			{
				name: "Downspout Extensions",
				lead_fee: 40,
			},
			{
				name: "Sump Pump Installation",
				lead_fee: 75,
			},
			{
				name: "Grading",
				lead_fee: 100,
			},
		],
	},
	{
		primary_service: "Outdoor Kitchens",
		sub_services: [
			{
				name: "Grill Installation",
				lead_fee: 75,
			},
			{
				name: "Sink Installation",
				lead_fee: 75,
			},
			{
				name: "Countertops",
				lead_fee: 100,
			},
			{
				name: "Storage Units",
				lead_fee: 75,
			},
		],
	},
	{
		primary_service: "Patio Enclosures",
		lead_fee: 150,
	},
	{
		primary_service: "Patios",
		sub_services: [
			{
				name: "Paver Installation",
				lead_fee: 125,
			},
			{
				name: "Concrete Patios",
				lead_fee: 100,
			},
			{
				name: "Stamped Concrete",
				lead_fee: 125,
			},
			{
				name: "Stonework",
				lead_fee: 100,
			},
		],
	},
	{
		primary_service: "Pest Control",
		sub_services: [
			{
				name: "Termite Treatment",
				lead_fee: 50,
			},
			{
				name: "Ant Control",
				lead_fee: 50,
			},
			{
				name: "Rodent Control",
				lead_fee: 50,
			},
			{
				name: "Extermination",
				lead_fee: 50,
			},
			{ name: "Mosquito Control", lead_fee: 75 },
		],
	},
	{ primary_service: "Mosquito", lead_fee: 75 },
	{
		primary_service: "Pest Inspection",
		lead_fee: 50,
	},
	{
		primary_service: "Plastering",
		sub_services: [
			{
				name: "Wall Plastering",
				lead_fee: 75,
			},
			{
				name: "Stucco Application",
				lead_fee: 75,
			},
			{
				name: "Repair Work",
				lead_fee: 40,
			},
			{
				name: "Finishing",
				lead_fee: 75,
			},
		],
	},
	{
		primary_service: "Plumbing Inspection",
		lead_fee: 50,
	},
	{
		primary_service: "Pool & Spa Inspection",
		lead_fee: 50,
	},
	{
		primary_service: "Pool Cleaning",
		sub_services: [
			{
				name: "Vacuuming",
				lead_fee: 50,
			},
			{
				name: "Filter Cleaning",
				lead_fee: 40,
			},
			{
				name: "Chemical Balancing",
				lead_fee: 25,
			},
			{
				name: "Skimming",
				lead_fee: 40,
			},
			{
				name: "Recurring Cleanings",
				lead_fee: 60,
			},
		],
	},
	{
		primary_service: "Pool Installers",
		sub_services: [
			{
				name: "Inground Pools",
				lead_fee: 225,
			},
			{
				name: "Above-Ground Pools",
				lead_fee: 150,
			},
			{
				name: "Custom Pool Design",
				lead_fee: 250,
			},
			{
				name: "Spa Installation",
				lead_fee: 150,
			},
		],
	},
	{
		primary_service: "Popcorn Ceiling Removal",
		sub_services: [
			{
				name: "Ceiling Scraping",
				lead_fee: 75,
			},
			{
				name: "Skim Coating",
				lead_fee: 50,
			},
			{
				name: "Texturing",
				lead_fee: 50,
			},
			{
				name: "Painting",
				lead_fee: 115,
			},
		],
	},
	{
		primary_service: "Pressure Washing",
		sub_services: [
			{
				name: "Deck Cleaning",
				lead_fee: 20,
			},
			{
				name: "Driveway Cleaning",
				lead_fee: 20,
			},
			{
				name: "House Washing",
				lead_fee: 50,
			},
			{
				name: "Roof Cleaning",
				lead_fee: 50,
			},
		],
	},
	{
		primary_service: "Professional Organizers",
		sub_services: [
			{
				name: "Closet Organizing",
				lead_fee: 50,
			},
			{
				name: "Office Organization",
				lead_fee: 50,
			},
			{
				name: "Decluttering",
				lead_fee: 50,
			},
			{
				name: "Storage Solutions",
				lead_fee: 50,
			},
		],
	},
	{
		primary_service: "Radon Inspection",
		lead_fee: 30,
	},
	{
		primary_service: "Roof Cleaning",
		lead_fee: 50,
	},
	{
		primary_service: "Roof Inspection",
		lead_fee: 50,
	},
	{
		primary_service: "General Roofing",
		sub_services: [
			{
				name: "Shingle Installation",
				lead_fee: 175,
			},
			{
				name: "Metal Roofing",
				lead_fee: 250,
			},
			{
				name: "Flat Roof Repair",
				lead_fee: 75,
			},
			{
				name: "Roof Replacement",
				lead_fee: 225,
			},
		],
	},
	{
		primary_service: "Screen Enclosures",
		sub_services: [
			{
				name: "Pool Enclosures",
				lead_fee: 175,
			},
			{
				name: "Patio Enclosures",
				lead_fee: 150,
			},
			{
				name: "Porch Screens",
				lead_fee: 125,
			},
			{
				name: "Repairs",
				lead_fee: 50,
			},
		],
	},
	{
		primary_service: "Septic & Well Repair",
		sub_services: [
			{
				name: "Pump Replacement",
				lead_fee: 30,
			},
			{
				name: "Tank Repair",
				lead_fee: 50,
			},
			{
				name: "Drain Field Repair",
				lead_fee: 50,
			},
			{
				name: "Water Quality Testing",
				lead_fee: 30,
			},
		],
	},
	{
		primary_service: "Shower Glass",
		sub_services: [
			{
				name: "Frameless Shower Doors",
				lead_fee: 150,
			},
			{
				name: "Sliding Doors",
				lead_fee: 125,
			},
			{
				name: "Glass Repair",
				lead_fee: 50,
			},
			{
				name: "Custom Glass",
				lead_fee: 150,
			},
		],
	},
	{
		primary_service: "Siding Repair & Installation",
		sub_services: [
			{
				name: "Vinyl Siding",
				lead_fee: 225,
			},
			{
				name: "Fiber Cement",
				lead_fee: 250,
			},
			{
				name: "Wood Siding",
				lead_fee: 250,
			},
			{
				name: "Repair and Replacement",
				lead_fee: 50,
			},
		],
	},
	{
		primary_service: "Soil Inspection",
		lead_fee: 50,
	},
	{
		primary_service: "Solar Panel Installation",
		lead_fee: 175,
	},
	{
		primary_service: "Stamped Concrete",
		lead_fee: 125,
	},
	{
		primary_service: "Standard Photography",
		lead_fee: 40,
	},
	{
		primary_service: "Stone & Gravel",
		sub_services: [
			{
				name: "Pathways",
				lead_fee: 75,
			},
			{
				name: "Garden Beds",
				lead_fee: 75,
			},
			{
				name: "Driveways",
				lead_fee: 125,
			},
		],
	},
	{
		primary_service: "Structural Engineering",
		sub_services: [
			{
				name: "Foundation Analysis",
				lead_fee: 50,
			},
			{
				name: "Structural Design",
				lead_fee: 175,
			},
			{
				name: "Building Inspections",
				lead_fee: 75,
			},
			{
				name: "Load Analysis",
				lead_fee: 50,
			},
		],
	},
	{
		primary_service: "Stucco",
		sub_services: [
			{
				name: "Application",
				lead_fee: 125,
			},
			{
				name: "Repair",
				lead_fee: 50,
			},
			{
				name: "Patching",
				lead_fee: 50,
			},
			{
				name: "Waterproofing",
				lead_fee: 100,
			},
		],
	},
	{
		primary_service: "Tile Installation",
		sub_services: [
			{
				name: "Floor Tile",
				lead_fee: 125,
			},
			{
				name: "Backsplash",
				lead_fee: 50,
			},
			{
				name: "Shower Tile",
				lead_fee: 125,
			},
			{
				name: "Outdoor Tile",
				lead_fee: 125,
			},
		],
	},
	{
		primary_service: "Junk Removal",
		sub_services: [
			{ name: "Junk Removal", lead_fee: 50 },
			{ name: "Dumpster Drop Off", lead_fee: 25 },
		],
	},
	{
		primary_service: "Trash Removal",
		sub_services: [
			{
				name: "Junk Removal",
				lead_fee: 50,
			},
			{
				name: "Construction Debris",
				lead_fee: 50,
			},
			{
				name: "Hazardous Waste Removal",
				lead_fee: 75,
			},
		],
	},
	{
		primary_service: "Tree Service",
		sub_services: [
			{
				name: "Tree Trimming",
				lead_fee: 50,
			},
			{
				name: "Tree Removal",
				lead_fee: 75,
			},
			{
				name: "Stump Grinding",
				lead_fee: 50,
			},
			{
				name: "Emergency Removal",
				lead_fee: 75,
			},
		],
	},
	{
		primary_service: "Wallpaper Hanger",
		lead_fee: 50,
	},
	{
		primary_service: "Wallpaper Removal",
		sub_services: [
			{
				name: "Stripping",
				lead_fee: 50,
			},
			{
				name: "Wall Preparation",
				lead_fee: 50,
			},
			{
				name: "Patch Repairs",
				lead_fee: 50,
			},
		],
	},
	{
		primary_service: "Water Damage Restoration",
		sub_services: [
			{
				name: "Flood Cleanup",
				lead_fee: 175,
			},
			{
				name: "Mold Prevention",
				lead_fee: 75,
			},
			{
				name: "Drying",
				lead_fee: 125,
			},
			{
				name: "Dehumidifying",
				lead_fee: 125,
			},
		],
	},
	{
		primary_service: "Well & Septic Inspection",
		lead_fee: 50,
	},
	{
		primary_service: "Window Cleaning",
		sub_services: [
			{
				name: "Interior Windows",
				lead_fee: 50,
			},
			{
				name: "Exterior Windows",
				lead_fee: 30,
			},
			{
				name: "High-Rise Window Cleaning",
				lead_fee: 75,
			},
			{
				name: "Screen Cleaning",
				lead_fee: 20,
			},
		],
	},
	{
		primary_service: "Window Replacement",
		sub_services: [
			{
				name: "Vinyl Windows",
				lead_fee: 125,
			},
			{
				name: "Wood Windows",
				lead_fee: 150,
			},
			{
				name: "Energy-Efficient Windows",
				lead_fee: 150,
			},
			{
				name: "Custom Windows",
				lead_fee: 175,
			},
		],
	},
	{
		primary_service: "Window Treatment",
		sub_services: [
			{
				name: "Blinds",
				lead_fee: 60,
			},
			{
				name: "Shades",
				lead_fee: 80,
			},
			{
				name: "Shutters",
				lead_fee: 100,
			},
			{
				name: "Draperies",
				lead_fee: 80,
			},
		],
	},
	{
		primary_service: "Mortgage Lender",
		lead_fee: 250,
	},

	{
		primary_service: "Appraiser",
		lead_fee: 150,
	},
	{
		primary_service: "Moving Service",
		sub_services: [
			{
				name: "Residential Moving",
				lead_fee: 175,
			},
			{
				name: "Commercial Moving",
				lead_fee: 175,
			},
			{
				name: "Packing Services",
				lead_fee: 125,
			},
			{
				name: "Storage Solutions",
				lead_fee: 75,
			},
		],
	},
	{
		primary_service: "Floor Installation",
		sub_services: [
			{
				name: "Hardwood",
				lead_fee: 200,
			},
			{
				name: "Laminate",
				lead_fee: 175,
			},
			{
				name: "Vinyl",
				lead_fee: 150,
			},
			{
				name: "Carpet",
				lead_fee: 125,
			},
		],
	},
	{
		primary_service: "Floor Repair",
		sub_services: [
			{
				name: "Hardwood",
				lead_fee: 50,
			},
			{
				name: "Laminate",
				lead_fee: 50,
			},
			{
				name: "Vinyl",
				lead_fee: 50,
			},
			{
				name: "Carpet",
				lead_fee: 50,
			},
		],
	},
	{
		primary_service: "Oil Tank Inspection",
		lead_fee: 50,
	},
	{
		primary_service: "Interior Design",
		lead_fee: 75,
	},
	{
		primary_service: "Water Quality Testing",
		lead_fee: 50,
	},
	{
		primary_service: "Sewer Line Inspection",
		lead_fee: 50,
	},
	{
		primary_service: "Radon Remediation",
		sub_services: [
			{
				name: "Radon Mitigation Systems",
				lead_fee: 50,
			},
			{
				name: "Sealing",
				lead_fee: 50,
			},
			{
				name: "Ventilation System Installation",
				lead_fee: 50,
			},
		],
	},
]
