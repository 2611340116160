import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { updateDoc } from "firebase/firestore"
import imageCompression from "browser-image-compression"
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"
import {
	Button,
	Center,
	Heading,
	FormControl,
	FormHelperText,
	FormErrorMessage,
	FormLabel,
	IconButton,
	Input,
	Image,
	Stack,
	Spinner,
	Text,
	Tooltip,
	useDisclosure,
} from "@chakra-ui/react"
import { InfoOutlineIcon } from "@chakra-ui/icons"
import PhoneInput from "react-phone-number-input/input"
import { isValidPhoneNumber } from "react-phone-number-input"
import { addDoc, collection, query, where, getDocs } from "firebase/firestore"
import { db, logUserEvent, storage } from "../../firebase"
import { analytic_events } from "../../analytics"

export const AgentProfile = ({ userPlus }) => {
	const navigate = useNavigate()
	const { isOpen, onOpen, onToggle, onClose } = useDisclosure()

	const [agentData, setAgentData] = useState({
		name: "",
		email: "",
		phoneNumber: "",
		profilePicture: null,
	})
	const [preview, setPreview] = useState(null)

	const [pageLoading, setPageLoading] = useState(false)
	const [formError, setFormError] = useState(false)

	const handleFileChange = async (event) => {
		const file = event.target.files[0]
		if (file) {
			try {
				const options = {
					maxSizeMB: 1,
					maxWidthOrHeight: 800,
					useWebWorker: true,
				}
				const compressedFile = await imageCompression(file, options)
				setAgentData({ ...agentData, profilePicture: compressedFile })

				// Generate a preview of the compressed image
				const reader = new FileReader()
				reader.onloadend = () => {
					setPreview(reader.result)
				}
				reader.readAsDataURL(compressedFile)
			} catch (error) {
				console.error("Error compressing image:", error)
			}
		}
	}

	useEffect(() => {
		if (userPlus === "pending") {
			return
		} else {
			setAgentData({
				name: userPlus.name,
				email: userPlus.email,
				phoneNumber: userPlus.phoneNumber ? userPlus.phoneNumber : null,
				profilePicture: userPlus.profilePicture
					? userPlus.profilePicture
					: null,
			})
			setPreview(userPlus.profilePicture)
		}
	}, [userPlus])

	const verifyAgentInformation = () => {
		const hasError =
			agentData.name === "" ||
			(agentData.companyEmail === "" && agentData.phoneNumber === "") ||
			(agentData.phoneNumber &&
				agentData.phoneNumber !== "" &&
				!isValidPhoneNumber(agentData.phoneNumber))

		if (hasError) {
			alert("Please correct fields before proceeding.")
		}
		setFormError(hasError)
		return hasError
	}

	const onSubmit = async (e) => {
		let imageUrl
		e.preventDefault()
		setPageLoading(true)
		const hasError = verifyAgentInformation()
		if (hasError) {
			setPageLoading(false)
			return
		}

		if (agentData.profilePicture !== null) {
			const storageRef = ref(storage, `agentProfileImages/${userPlus.id}.jpg`)

			await uploadBytes(storageRef, agentData.profilePicture)
				.then((snapshot) => {
					return getDownloadURL(storageRef)
				})
				.then((url) => {
					imageUrl = url
				})
				.catch((error) => {
					console.error("Error uploading image or getting download URL:", error)
				})
		}

		await updateDoc(userPlus.userDocRef, {
			_updatedAt: new Date(),
			name: agentData.name,
			phoneNumber: agentData.phoneNumber,
			profilePicture: imageUrl ? imageUrl : null,
		})

		const q = query(
			collection(db, "publicAgentProfile"),
			where("id", "==", userPlus.id)
		)

		const querySnapshot = await getDocs(q)

		if (querySnapshot.docs.length === 0) {
			// No documents found, add a new document
			await addDoc(collection(db, "publicAgentProfile"), {
				id: userPlus.id,
				_updatedAt: new Date(),
				name: agentData.name,
				email: agentData.email,
				phoneNumber: agentData.phoneNumber,
				photo: imageUrl ? imageUrl : null,
			})
		} else {
			// Update existing documents
			const updatePromises = querySnapshot.docs.map((doc) =>
				updateDoc(doc.ref, {
					_updatedAt: new Date(),
					name: agentData.name,
					email: agentData.email,
					phoneNumber: agentData.phoneNumber,
					photo: imageUrl ? imageUrl : null,
				})
			)

			await Promise.all(updatePromises)
		}

		await logUserEvent(analytic_events.AGENT_USER_PROFILE_UPDATE, userPlus.id, {
			name: agentData.name,
		})
		navigate("/vendors")
	}

	const handleChange = (input, e) => {
		setAgentData({ ...agentData, [input]: e })
	}

	const handleRemoveImage = () => {
		setAgentData({ ...agentData, profilePicture: null })
		setPreview(null)
	}

	if (pageLoading) {
		return (
			<Center mt="50px">
				<Stack>
					<Spinner m="auto" />
				</Stack>
			</Center>
		)
	}

	return (
		<Center
			flexDirection="column"
			maxW="629px"
			margin={{ base: "15px", md: "auto" }}
		>
			<Stack w="100%" gap={3}>
				<Heading size="lg" fontWeight="bold" mt="30px">
					Profile
				</Heading>
				<Text>
					Set up your profile to share your contact info and photo with clients
					and vendors, keeping you and your business top of mind.
				</Text>

				<FormControl isInvalid={formError && agentData.name === ""}>
					<FormLabel>Name</FormLabel>
					<Input
						bgColor="white"
						type="text"
						name="name"
						placeholder="Enter your name"
						value={agentData.name}
						onChange={(e) => {
							handleChange("name", e.target.value)
						}}
					/>
					{formError && agentData.name === "" && (
						<FormErrorMessage>Name is required.</FormErrorMessage>
					)}
				</FormControl>
				<FormControl isDisabled={userPlus.email}>
					<FormLabel>
						Email
						<Tooltip
							label="Email can’t be edited. To update it, create a new Quiplo account with your new email, then contact us at contact@quiploteams.com to transfer your client insights and job history."
							fontSize="sm"
							isOpen={isOpen}
						>
							<IconButton
								variant="tooltip"
								height="auto"
								onMouseEnter={onOpen}
								onMouseLeave={onClose}
								onClick={onToggle}
								icon={<InfoOutlineIcon />}
							/>
						</Tooltip>
					</FormLabel>
					<Input
						bgColor="white"
						type="text"
						name="companyEmail"
						placeholder="Enter your contact email"
						value={agentData.email}
						onChange={(e) => {
							handleChange("email", e.target.value.toLowerCase())
						}}
					/>
				</FormControl>
				<FormControl
					isInvalid={
						formError &&
						(agentData.phoneNumber === "" ||
							(agentData.phoneNumber &&
								!isValidPhoneNumber(agentData.phoneNumber)))
					}
				>
					<FormLabel>Phone Number</FormLabel>
					<PhoneInput
						country="US"
						style={{
							width: "100%",
							height: "40px",
							padding: "0.5rem 0.75rem",
							border:
								formError &&
								(agentData.phoneNumber === "" ||
									(agentData.phoneNumber &&
										!isValidPhoneNumber(agentData.phoneNumber)))
									? "1px solid #F05252"
									: "1px solid #CBD5E0",
							borderRadius: "0.375rem",
							outlineColor:
								formError &&
								(agentData.phoneNumber === "" ||
									(agentData.phoneNumber &&
										!isValidPhoneNumber(agentData.phoneNumber)))
									? "#F05252"
									: "#CBD5E0",
						}}
						placeholder="Enter phone number"
						value={agentData.phoneNumber}
						onChange={(e) => {
							setAgentData((prevagentData) => ({
								...prevagentData,
								phoneNumber: e,
							}))
						}}
						onFocus={(e) => {
							e.target.style.borderColor = "#4299e1"
							e.target.style.boxShadow = "0 0 0 3px rgba(66, 153, 225, 0.1)"
							e.target.style.outlineColor = "#4299e1"
						}}
						onBlur={(e) => {
							e.target.style.borderColor =
								formError &&
								(agentData.phoneNumber === "" ||
									(agentData.phoneNumber &&
										!isValidPhoneNumber(agentData.phoneNumber)))
									? "#F05252"
									: "#CBD5E0"
							e.target.style.boxShadow =
								formError &&
								(agentData.phoneNumber === "" ||
									(agentData.phoneNumber &&
										!isValidPhoneNumber(agentData.phoneNumber)))
									? "0 0 0 1px #F05252"
									: "none"
						}}
					/>
					{formError &&
						(agentData.phoneNumber === "" ||
							(agentData.phoneNumber &&
								!isValidPhoneNumber(agentData.phoneNumber))) && (
							<FormErrorMessage>
								Please enter a valid phone number.
							</FormErrorMessage>
						)}
				</FormControl>
				<FormControl>
					<FormLabel>Profile Picture</FormLabel>
					<Input
						bgColor="white"
						py="1"
						type="file"
						accept="image/jpeg"
						onChange={handleFileChange}
					/>
					<FormHelperText>
						Upload a profile picture in jpeg format
					</FormHelperText>
					{preview && (
						<>
							<Image
								src={preview}
								alt="Profile Preview"
								mt={4}
								w="300px"
								h="300px"
							/>
							<Button mt={2} colorScheme="red" onClick={handleRemoveImage}>
								Remove
							</Button>
						</>
					)}
				</FormControl>
				<Button
					w="100%"
					bgColor="borderColor"
					color="white"
					onClick={onSubmit}
					mt="30px"
					mb="40px"
				>
					Save
				</Button>
			</Stack>
		</Center>
	)
}
