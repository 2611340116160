import React, { useState, useContext } from "react"
import axios from "axios"
import {
	Button,
	Modal,
	ModalOverlay,
	ModalHeader,
	ModalFooter,
	ModalContent,
	ModalCloseButton,
	ModalBody,
} from "@chakra-ui/react"
import {
	collection,
	query,
	where,
	getDocs,
	updateDoc,
} from "firebase/firestore"
import { db, logUserEvent } from "../firebase"
import { formatPhoneNumber } from "../Utilities"
import { UserContext } from "../Contexts/UserContext"
import { analytic_events } from "../analytics"
import config from "../config.js"

export const HPVJobRequestScheduleModal = ({
	jobRequest,
	jobRequestResponse,
	isOpen,
	onClose,
}) => {
	const { userPlus } = useContext(UserContext)
	const [isScheduling, setIsScheduling] = useState(false)
	const onSubmit = async () => {
		if (isScheduling) return

		setIsScheduling(true)
		const q = query(
			collection(db, "jobRequests"),
			where("id", "==", jobRequest.id)
		)
		const docSnap = await getDocs(q)

		await updateDoc(docSnap.docs[0].ref, {
			selectedResponseId: jobRequestResponse.id,
		})
		await logUserEvent(analytic_events.AGENT_CLIENT_SCHEDULED, userPlus.id, {
			scheduledResponsesId: jobRequestResponse.id,
			userId: userPlus.id,
			partner: "HPV",
		})
		sendSchedulingEmail(jobRequestResponse)
		setIsScheduling(false)
		onClose()
		window.open(jobRequestResponse.calendlyLink, "_blank")
	}

	const sendSchedulingEmail = async (jobRequestResponse, e) => {
		const userEmail = userPlus.email
		const userName = userPlus.name
		try {
			await axios.post(`${config.baseURL}/api/hpv-job-request-scheduling`, {
				jobRequestResponse,
				jobRequest,
				userEmail,
				userName,
			})
		} catch (error) {
			console.error("Error sending email:", error)
		}
	}

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="3xl">
			<ModalOverlay />
			<ModalContent mx={{ base: "10px", md: "0px" }}>
				<ModalHeader>
					Schedule {jobRequest.serviceType} - {jobRequest.subServiceType}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<p>
						Select a date and time and we will connect you and{" "}
						{jobRequestResponse.vendorName
							? jobRequestResponse.vendorName
							: jobRequestResponse.vendorContactEmail}{" "}
						on an email to coordinate your {jobRequest.serviceType} job for{" "}
						{jobRequest.locationAddress}. <br />
						<br />
						{jobRequestResponse.vendorName
							? jobRequestResponse.vendorName
							: jobRequestResponse.vendorContactEmail}{" "}
						contact info:
						<br />
						Email: {jobRequestResponse.vendorContactEmail}
						<br />
						Phone: {formatPhoneNumber(jobRequestResponse.vendorPhoneNumber)}
					</p>
				</ModalBody>
				<ModalFooter>
					<Button
						mr={3}
						onClick={onSubmit}
						w="100%"
						bg="borderColor"
						color="white"
						_hover={{ bgColor: "borderColor" }}
					>
						Book Time
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
