import { Box, Image, Text } from "@chakra-ui/react"

export const EmptyState = ({ helperText, partner }) => {
	return (
		<Box w="100%" m="auto" pb="40px">
			<Image
				src={
					partner === "HPV"
						? "/assets/hpv_house_landscape.png"
						: "/assets/house_landscape.png"
				}
				alt="empty state"
				m="auto"
			/>
			<Text textAlign="center">{helperText}</Text>
		</Box>
	)
}
