import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import {
	getDocs,
	query,
	where,
	collection,
	addDoc,
	doc,
	getDoc,
	updateDoc,
	increment,
} from "firebase/firestore"
import {
	Box,
	Button,
	Divider,
	Heading,
	Tag,
	TagLabel,
	IconButton,
	Stack,
	UnorderedList,
	ListItem,
} from "@chakra-ui/react"
import { db, logUserEvent } from "../firebase"
import { SmallCloseIcon } from "@chakra-ui/icons"
import { UserContext } from "../Contexts/UserContext"
import { analytic_events } from "../analytics"
import config from "../config.js"
import { formatPhoneNumber } from "../Utilities"

export const VendorInviteOverview = ({
	vendorList,
	setVendorData,
	setVendorList,
	setTcTeam,
	setTcAgent,
	setTeamMembersSelected,
	teamMembersSelected,
	setEditingIndex,
	previousStep,
}) => {
	const { userPlus } = useContext(UserContext)
	const navigate = useNavigate()

	const handleEditVendor = async (index) => {
		await setVendorData({ ...vendorList[index] })
		await setTcTeam(vendorList[index].teamId)
		await setTeamMembersSelected(vendorList[index].tcAgentId)
		await setEditingIndex(index)
		previousStep()
	}

	const handleVendorRemoval = (index) => {
		const newServiceRequests = [...vendorList]
		newServiceRequests.splice(index, 1)
		setVendorList(newServiceRequests)
	}

	const onSubmitVendors = async (e) => {
		e.preventDefault()
		await Promise.all(
			vendorList.map(async (vendor) => {
				try {
					let invitedQuery
					if (vendor.companyEmail !== "") {
						invitedQuery = query(
							collection(db, "users"),
							where("email", "==", vendor.companyEmail)
						)
					} else {
						invitedQuery = query(
							collection(db, "users"),
							where("phoneNumber", "==", vendor.phoneNumber)
						)
					}

					const doc1 = await getDocs(invitedQuery)
					const userExists = doc1.docs.length > 0
					if (userExists) {
						const existingUserId = doc1.docs[0].data().id

						const relationshipQuery = query(
							collection(db, "agentVendor"),
							where(
								"agentId",
								"==",
								vendor.tcAgentId ? vendor.tcAgentId : userPlus.id
							),
							where("vendorId", "==", existingUserId)
						)
						const association = await getDocs(relationshipQuery)
						const isUserAlreadyAssociated = association.docs.length > 0
						if (!isUserAlreadyAssociated) {
							try {
								await addDoc(collection(db, "agentVendor"), {
									id: crypto.randomUUID(),
									vendorId: existingUserId,
									agentId: vendor.tcAgent ? vendor.tcAgentId : userPlus.id,
									...(userPlus.isAdmin && {
										teamId: vendor.teamId ? vendor.teamId : userPlus.teamId,
									}),
								})
							} catch (error) {
								console.error("Error adding user and associating:", error)
							}
						}
					} else {
						try {
							const userDoc = await addDoc(collection(db, "users"), {
								id: crypto.randomUUID(),
								email:
									vendor.companyEmail !== ""
										? vendor.companyEmail.toLowerCase()
										: null,
								name: vendor.name,
								phoneNumber:
									vendor.phoneNumber !== "" ? vendor.phoneNumber : null,
								invitedBy: userPlus.id,
								teamId: null,
								userType: "vendor",
								numberOfServices: 0,
								...(userPlus.partner === "HPV" &&
									userPlus.isAdmin && { partner: "HPV" }),
								_createdAt: new Date(),
							})

							const docRef = doc(db, "users", userDoc.id)
							const newUser = await getDoc(docRef)
							const userId = newUser.data().id
							await addDoc(collection(db, "agentVendor"), {
								id: crypto.randomUUID(),
								agentId: vendor.tcAgentId ? vendor.tcAgentId : userPlus.id,
								vendorId: userId,
								...(userPlus.isAdmin && {
									teamId: vendor.teamId ? vendor.teamId : userPlus.teamId,
								}),
							})

							await Promise.all(
								vendor.serviceTypes.map(async (serviceType) => {
									const id = crypto.randomUUID()
									await addDoc(collection(db, "services"), {
										id,
										userId: userId,
										_createdAt: new Date(),
										_updatedAt: new Date(),
										_createdBy: userPlus.id,
										_updatedBy: userPlus.id,
										serviceType,
										contactEmail:
											vendor.companyEmail !== "" ? vendor.companyEmail : null,
										phoneNumber:
											vendor.phoneNumber !== "" ? vendor.phoneNumber : null,
										name: vendor.name,
										...(userPlus.partner === "HPV" &&
											userPlus.isAdmin &&
											userPlus.userType === "vendor" && { partner: "HPV" }),
										...(userPlus.partner === "HPV" &&
											userPlus.isAdmin &&
											userPlus.userType === "vendor" && {
												zipCodesServiced: vendor.zipCodesServiced,
											}),
									})
									await addDoc(collection(db, "vendorServiceMap"), {
										_createdAt: new Date(),
										vendorId: userId,
										serviceId: id,
										...(userPlus.partner === "HPV" &&
											userPlus.isAdmin && { partner: "HPV" }),
									})
									if (vendor.tcAgentId) {
										await addDoc(collection(db, "userPreferredServices"), {
											_createdAt: new Date(),
											userId: vendor.tcAgentId,
											serviceId: id,
											teamId: vendor.tcTeam ? vendor.tcTeam : null,
											vendorId: userId,
										})
									}
								})
							)

							await updateDoc(docRef, {
								numberOfServices: increment(vendor.serviceTypes.length),
							})

							await logUserEvent(analytic_events.INVITE_SENT, userPlus.id, {
								email: vendor.companyEmail,
								phoneNumber: vendor.phoneNumber,
								inviteType: "vendor",
								invitedBy: userPlus.id,
							})
						} catch (error) {
							console.error("Error adding user and associating:", error)
						}
					}
				} catch (error) {
					console.error("Error adding user and associating:", error)
				}
			})
		)
		await sendVendorInvite()
		navigate(userPlus.userType === "vendor" ? "/services" : "/vendors")
	}

	const sendVendorInvite = async () => {
		await Promise.all(
			vendorList.map(async (vendor) => {
				const userName = vendor.tcAgentName ? vendor.tcAgentName : userPlus.name
				const userEmail = vendor.tcAgentEmail
					? vendor.tcAgentEmail
					: userPlus.email
				if (vendor.companyEmail !== "") {
					if (userPlus.partner === "HPV") {
						await axios.post(
							`${config.baseURL}/api/invite-vendor-to-homeProValet`,
							{
								vendorEmail: vendor.companyEmail,
								serviceType: vendor.serviceTypes.join(", "),
								name: vendor.name,
								userEmail,
								userName,
							}
						)
					} else {
						await axios.post(`${config.baseURL}/api/invite-vendor-to-quiplo`, {
							vendorEmail: vendor.companyEmail,
							serviceType: vendor.serviceTypes.join(", "),
							name: vendor.name,
							userEmail,
							userName,
						})
					}
				} else {
					try {
						await axios.post(`${config.baseURL}/api/sms-invite-user`, {
							partner: userPlus.partner,
							isHPVMaster: userPlus.isAdmin && userPlus.userType === "vendor",
							phoneNumber: vendor.phoneNumber,
							serviceType: vendor.serviceTypes.join(", "),
							agentName: userPlus.name,
						})
					} catch (error) {
						console.error("Error sending email:", error)
					}
				}
			})
		)
	}

	return (
		<Box width="100%" mt="30px">
			<Heading size="lg" mb="20px" fontWeight="semibold">
				{userPlus.partner ? "Home Pros" : "Vendors"}
			</Heading>
			{vendorList.map((vendor, index) => (
				<>
					<Box
						display="flex"
						flexDirection={{ base: "column", md: "row" }}
						justifyContent={{ md: "space-between" }}
						key={index}
						mt="20px"
					>
						<Stack>
							<Heading size="md" mb="10px" fontWeight="semibold">
								{vendor.name}
							</Heading>
							<Tag
								borderRadius="full"
								variant="solid"
								colorScheme="gray"
								mr="5px"
								mb="5px"
							>
								<TagLabel>
									{vendor.companyEmail !== ""
										? vendor.companyEmail
										: formatPhoneNumber(vendor.phoneNumber)}
								</TagLabel>
							</Tag>
							<UnorderedList>
								{vendor.serviceTypes.map((serviceType) => (
									<ListItem>{serviceType}</ListItem>
								))}
							</UnorderedList>
						</Stack>
						<Box display="flex" flexDirection={{ base: "column", md: "row" }}>
							<IconButton
								display={{ base: "none", md: "flex" }}
								size="sm"
								aria-label="Delete Service Request"
								icon={<SmallCloseIcon />}
								onClick={() => handleVendorRemoval(index)}
								variant="ghost"
								colorScheme="red"
							/>
							<Button
								display={{ base: "flex", md: "none" }}
								w="100%"
								mt="10px"
								onClick={() => handleVendorRemoval(index)}
							>
								{" "}
								Remove Service Request{" "}
							</Button>
							<Button
								size={{ base: "md", md: "sm" }}
								w={{ base: "100%", md: "" }}
								mt={{ base: "10px", md: "0px" }}
								bgColor="blue.500"
								color="white"
								onClick={() => handleEditVendor(index)}
							>
								Edit
							</Button>
						</Box>
					</Box>
					{index < vendorList.length - 1 && <Divider my="15px" />}
				</>
			))}
			<Button
				onClick={onSubmitVendors}
				w="100%"
				bgColor="borderColor"
				color="white"
				mt="40px"
			>
				Send Invites
			</Button>
		</Box>
	)
}
