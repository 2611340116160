import { NavLink } from "react-router-dom"
import {
	Box,
	Button,
	Card,
	CardHeader,
	CardBody,
	HStack,
	Stack,
	StackDivider,
	Text,
} from "@chakra-ui/react"
import { dateToFormat } from "../Utilities"

export const VendorJobRequestListMobileCard = ({
	jobRequest,
	index,
	jobResponsesRequestIds,
	jobRequestIds,
	userPlus,
	vendorServices,
}) => {
	return (
		<Card w="100%" mb="20px" key={index}>
			<CardHeader
				fontWeight="semibold"
				filter="auto"
				blur={
					!vendorServices.includes(jobRequest.serviceType) ||
					(!userPlus.activePlan && !userPlus.partner)
						? "3px"
						: null
				}
			>
				{jobRequest.locationAddress}
			</CardHeader>
			<CardBody fontSize="sm">
				<Stack divider={<StackDivider />} spacing="1">
					<Box display="flex" justifyContent="space-between">
						<Text fontWeight="semibold">Service Type:</Text>
						<Text> {jobRequest.serviceType}</Text>
					</Box>
					<Box
						display="flex"
						justifyContent="space-between"
						filter="auto"
						blur={
							!vendorServices.includes(jobRequest.serviceType) ||
							(!userPlus.activePlan && !userPlus.partner)
								? "3px"
								: null
						}
					>
						<Text fontWeight="semibold">Client:</Text>
						<Text style={{ wordWrap: "break-word", width: "75%" }}>
							{" "}
							{jobRequest.userEmail}
						</Text>
					</Box>
					<Box display="flex" justifyContent="space-between">
						<Text fontWeight="semibold">Preferred Dates:</Text>
						{jobRequest.servicePreferredDates &&
							jobRequest.servicePreferredDates.map((entry, index) => (
								<Stack gap={5} mb="5px" key={index}>
									<Text key={index}>{dateToFormat(entry.date)}</Text>
								</Stack>
							))}
					</Box>
					<Box display="flex" justifyContent="space-between">
						<Text fontWeight="semibold">Job Status:</Text>
						{jobRequest.selectedResponseId &&
							jobResponsesRequestIds.some(
								(response) =>
									response.responseId === jobRequest.selectedResponseId &&
									response.requestId === jobRequest.id
							) &&
							(userPlus.partner ? (
								<Text>Request Booking</Text>
							) : (
								<Text>
									Request to schedule - {dateToFormat(jobRequest.scheduleDate)}
								</Text>
							))}
						{jobResponsesRequestIds.some(
							(response) => response.requestId === jobRequest.id
						) &&
							!jobRequest.selectedResponseId && <Text> Client Received</Text>}
						{!jobResponsesRequestIds.some(
							(response) => response.requestId === jobRequest.id
						) &&
							!jobRequest.selectedResponseId && <Text>Response Needed</Text>}
						{jobRequest.selectedResponseId &&
							!jobResponsesRequestIds.some(
								(response) =>
									response.responseId === jobRequest.selectedResponseId
							) && <Text> Unsuccessful</Text>}
					</Box>
				</Stack>
				{!jobResponsesRequestIds.includes(jobRequest.id) &&
					!jobRequest.selectedResponseId && (
						<Button
							as={NavLink}
							to={`/job-request/${jobRequest.id}`}
							w="100%"
							mt="10px"
						>
							Respond to Job Request
						</Button>
					)}
			</CardBody>
		</Card>
	)
}
