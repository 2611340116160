import { useState, useContext } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import {
	addDoc,
	collection,
	query,
	where,
	getDocs,
	updateDoc,
	doc,
	getDoc,
} from "firebase/firestore"
import { Box, Center, Heading, Text, Spinner } from "@chakra-ui/react"

import { VendorInviteForm } from "../Components/VendorInviteForm"
import { InviteTypeForm } from "../Components/InviteTypeForm"
import { VendorInviteOverview } from "../Components/VendorInviteOverview"
import { HPVBanner } from "../Components/HPVBanner"
import { db, logUserEvent } from "../firebase"
import { analytic_events } from "../analytics"
import { UserContext } from "../Contexts/UserContext"
import { HPV_DEFAULT_VENDOR_ID, HPV_DEFAULT_AGENT_ID } from "../enums"
import config from "../config.js"

export const Invite = () => {
	const { userPlus } = useContext(UserContext)
	const navigate = useNavigate()

	// State management
	const [step, setStep] = useState(1)
	const [value, setValue] = useState(
		userPlus?.userType === "vendor" ? "agent" : ""
	)
	const [editingIndex, setEditingIndex] = useState(null)
	const [inviteEmails, setInviteEmails] = useState([])
	const [vendorData, setVendorData] = useState({
		name: "",
		companyEmail: "",
		phoneNumber: "",
		serviceTypes: [],
		serviceStates: [],
		serviceCities: [],
		zipCodesServiced: [],
	})
	const [vendorList, setVendorList] = useState([])
	const [pageLoading, setPageLoading] = useState(false)
	const [tcTeam, setTcTeam] = useState(null)
	const [tcAgent, setTcAgent] = useState(null)

	const handleAgentVendorAssociation = async (existingUser, userDocRef) => {
		try {
			const existingUserId = existingUser.id

			if (userPlus.userType === "vendor" && !userPlus.partner) {
				// Check for existing agent-vendor association
				const relationshipQuery = query(
					collection(db, "agentVendor"),
					where("vendorId", "==", userPlus.id),
					where("agentId", "==", existingUserId)
				)

				const association = await getDocs(relationshipQuery)

				if (association.docs.length === 0 && !userPlus.isAdmin) {
					// Create new agent-vendor association
					const docData = {
						id: crypto.randomUUID(),
						agentId: existingUserId,
						vendorId: userPlus.id,
						...(userPlus.partner && { partner: userPlus.partner }),
					}
					await addDoc(collection(db, "agentVendor"), docData)
				}
			} else if (userPlus.userType === "agent") {
				// Handle agent-client relationship
				const relationshipQuery = query(
					collection(db, "agentClient"),
					where("clientId", "==", existingUserId)
				)

				const association = await getDocs(relationshipQuery)
				const isUserAlreadyAssociated = association.docs.length > 0

				if (!isUserAlreadyAssociated) {
					// Create new agent-client relationship
					const docData = {
						id: crypto.randomUUID(),
						agentId: userPlus.id,
						clientId: existingUserId,
						teamId: userPlus.teamId || null,
					}
					await addDoc(collection(db, "agentClient"), docData)
				} else if (association.docs[0].agentId !== userPlus.id) {
					// Update agent-client relationship to reflect new agent
					const assocDocRef = association.docs[0].ref
					await updateDoc(assocDocRef, {
						agentId: userPlus.id,
						teamId: userPlus.teamId || null,
					})
					await updateDoc(userDocRef, {
						invitedBy: userPlus.id,
					})
				}
			}
		} catch (error) {
			console.error("Error in handleAgentVendorAssociation:", error)
		}
	}

	const handleNewUserAssociation = async (existingUser, inviteEmail) => {
		try {
			const existingUserId = existingUser.id

			if (userPlus.userType === "vendor" && userPlus.isAdmin) {
				return
			}

			if (userPlus.userType === "vendor" && !userPlus.isAdmin) {
				// Create vendor-agent relationship
				const docData = {
					id: crypto.randomUUID(),
					agentId: existingUserId,
					vendorId: userPlus.id,
					...(userPlus.partner && { partner: userPlus.partner }),
				}
				await addDoc(collection(db, "agentVendor"), docData)
			} else {
				// Create agent-client relationship
				const docData = {
					id: crypto.randomUUID(),
					agentId: userPlus.userType === "TC" ? tcAgent : userPlus.id,
					clientId: existingUserId,
					teamId: userPlus.userType === "TC" ? tcTeam : userPlus.teamId || null,
				}
				await addDoc(collection(db, "agentClient"), docData)
			}

			// Log the invite event
			await logUserEvent(analytic_events.INVITE_SENT, userPlus.id, {
				email: inviteEmail,
				inviteType: value,
				invitedBy: userPlus.id,
			})
		} catch (error) {
			console.error("Error in handleNewUserAssociation:", error)
		}
	}

	// Handle user invite
	const handleClientUser = async (inviteEmail) => {
		try {
			const invitedQuery = query(
				collection(db, "users"),
				where("email", "==", inviteEmail.toLowerCase())
			)
			const existingUsers = await getDocs(invitedQuery)
			const userExists = existingUsers.docs.length > 0

			if (userExists) {
				// Existing user logic
				const existingUser = existingUsers.docs[0].data()
				const userDocRef = existingUsers.docs[0].ref

				if (
					["agent", "TC"].includes(userPlus.userType) &&
					existingUser.userType === "agent"
				) {
					await handleAgentVendorAssociation(existingUser, userDocRef)
				} else {
					await handleNewUserAssociation(existingUser, inviteEmail)
				}
			} else {
				// New user creation and association
				await createAndAssociateNewUser(inviteEmail)
			}
		} catch (error) {
			console.error("Error handling user:", error)
		}
	}

	// Handle new user association
	const createAndAssociateNewUser = async (inviteEmail) => {
		try {
			const userData = {
				id: crypto.randomUUID(),
				email: inviteEmail.toLowerCase(),
				invitedBy: tcAgent ? tcAgent : userPlus.id,
				userType: value,
				teamId: userPlus.teamId || (tcTeam ? tcTeam : null),
				_createdAt: new Date(),
				...(userPlus.partner && { partner: userPlus.partner }),
				...(userPlus.partner === "HPV" &&
					userPlus.userType === "vendor" &&
					!userPlus.isAdmin && { sponsoredBy: userPlus.id }),
				...(userPlus.sponsoredBy && { sponsoredBy: userPlus.sponsoredBy }),
				...(userPlus.partner === "HPV" &&
					userPlus.userType === "vendor" &&
					!userPlus.isAdmin && { sponsoredBy: userPlus.id }),
				...(userPlus.userType === "vendor" &&
					!userPlus.isAdmin &&
					value === "client" && { teamId: "HPVT" }),
			}

			const userDoc = await addDoc(collection(db, "users"), userData)
			const newUser = (await getDoc(doc(db, "users", userDoc.id))).data()

			if (userPlus.userType === "vendor" && value !== "client") {
				await addVendorAssociation(newUser.id)
			}
			if (
				userPlus.userType !== "vendor" ||
				(userPlus.userType === "vendor" &&
					value === "client" &&
					userPlus.partner === "HPV")
			) {
				await addAgentClientAssociation(newUser.id)
			}
		} catch (error) {
			console.error("Error creating new user:", error)
		}
	}

	const addVendorAssociation = async (newUserId) => {
		const docData = {
			id: crypto.randomUUID(),
			agentId: newUserId,
			vendorId:
				userPlus.partner && userPlus.isAdmin
					? HPV_DEFAULT_VENDOR_ID
					: userPlus.id,
			...(userPlus.partner && { partner: userPlus.partner }),
		}
		if (userPlus.partner && userPlus.isAdmin) {
			return
		}
		await addDoc(collection(db, "agentVendor"), docData)
		await logUserEvent(analytic_events.INVITE_SENT, userPlus.id, {
			email: inviteEmails,
			inviteType: "agent",
			invitedBy: userPlus.id,
		})
	}

	const addAgentClientAssociation = async (newUserId) => {
		let agentId
		let teamId
		if (userPlus.userType === "TC") {
			agentId = tcAgent
			teamId = tcTeam
		} else if (userPlus.userType === "vendor" && !userPlus.isAdmin) {
			agentId = HPV_DEFAULT_AGENT_ID
			teamId = "HPVT"
		} else if (userPlus.userType === "vendor" && userPlus.isAdmin) {
			teamId = tcTeam
			agentId = tcAgent
		} else {
			agentId = userPlus.id
		}

		const docData = {
			id: crypto.randomUUID(),
			agentId,
			clientId: newUserId,
			teamId,
		}

		await addDoc(collection(db, "agentClient"), docData)
		await logUserEvent(analytic_events.INVITE_SENT, userPlus.id, {
			email: inviteEmails,
			inviteType: "client",
			invitedBy: userPlus.id,
		})
	}

	const onSubmit = async () => {
		try {
			setPageLoading(true)
			await Promise.all(inviteEmails.map(handleClientUser))
			if (value === "client") {
				if (userPlus.userType === "agent") {
					sendClientInviteEmail(
						userPlus.name,
						inviteEmails,
						userPlus.email,
						userPlus.id
					)
				}
				if (userPlus.userType === "vendor" && userPlus.partner) {
					sendInviteEmail(userPlus.name, inviteEmails, userPlus.email)
				} else {
					const q = query(collection(db, "users"), where("id", "==", tcAgent))
					const doc = await getDocs(q)
					const user = doc.docs[0].data()
					sendClientInviteEmail(user.name, inviteEmails, user.email, tcAgent)
				}
			} else {
				sendInviteEmail(userPlus.name, inviteEmails, userPlus.email)
			}
		} catch (error) {
			console.error("Error submitting invites:", error)
		} finally {
			setPageLoading(false)
			navigate("/job-requests")
		}
	}

	const sendInviteEmail = async (userName, inviteEmails, userEmail) => {
		if (Array.isArray(inviteEmails)) {
			await Promise.all(
				inviteEmails.map(async (inviteeEmail) => {
					if (userPlus.partner) {
						try {
							await axios.post(`${config.baseURL}/api/invite-to-homeprovalet`, {
								inviteeEmail,
							})
						} catch (error) {
							console.error("Error sending email:", error)
						}
					} else {
						try {
							await axios.post(`${config.baseURL}/api/invite-to-quiplo`, {
								inviteeEmail,
								userEmail,
								userName,
							})
						} catch (error) {
							console.error("Error sending email:", error)
						}
					}
				})
			)
		} else {
			if (userPlus.partner) {
				await axios.post(`${config.baseURL}/api/invite-to-homeprovalet`, {
					inviteeEmail: inviteEmails,
				})
			} else {
				await axios.post(`${config.baseURL}/api/invite-to-quiplo`, {
					inviteeEmail: inviteEmails,
					userEmail,
					userName,
				})
			}
		}
	}

	const sendClientInviteEmail = async (
		userName,
		inviteEmails,
		userEmail,
		userId
	) => {
		if (Array.isArray(inviteEmails)) {
			await Promise.all(
				inviteEmails.map(async (inviteeEmail) => {
					if (userPlus.partner) {
						try {
							await axios.post(
								`${config.baseURL}/api/invite-client-to-homeprovalet`,
								{
									inviteeEmail,
									userEmail,
									userName,
									userId,
								}
							)
						} catch (error) {
							console.error("Error sending email:", error)
						}
					} else {
						try {
							await axios.post(
								`${config.baseURL}/api/invite-client-to-quiplo`,
								{
									inviteeEmail,
									userEmail,
									userName,
									userId,
								}
							)
						} catch (error) {
							console.error("Error sending email:", error)
						}
					}
				})
			)
		} else {
			if (userPlus.partner) {
				await axios.post(
					`${config.baseURL}/api/invite-client-to-homeprovalet`,
					{
						inviteeEmail: inviteEmails,
						userEmail,
						userName,
						userId,
					}
				)
			} else {
				await axios.post(`${config.baseURL}/api/invite-client-to-quiplo`, {
					inviteeEmail: inviteEmails,
					userEmail,
					userName,
					userId,
				})
			}
		}
	}

	if (pageLoading) {
		return (
			<Center mt="50px">
				<Spinner />
			</Center>
		)
	}

	return (
		<>
			{userPlus.partner === "HPV" &&
				!userPlus.activePlan &&
				userPlus.userType === "vendor" &&
				!userPlus.isAdmin && (
					<HPVBanner location="inviteNoSub" userPlus={userPlus} />
				)}
			{userPlus.partner === "HPV" && userPlus.activePlan && (
				<HPVBanner location="invite" userPlus={userPlus} />
			)}
			<Box
				ml={{ base: "15px", lg: "93px" }}
				mr={{ base: "15px", lg: "93px" }}
				mt="30px"
			>
				<Heading size="lg" fontWeight="bold">
					{`Invite others to ${
						userPlus.partner === "HPV" ? "Home Pro Valet" : "Quiplo"
					}`}
				</Heading>
				<Text>
					Invite your preferred network of vendors, colleagues, and clients.
				</Text>
				<Center
					maxW="612px"
					display="flex"
					flexDirection="column"
					margin="auto"
				>
					{step === 1 && (
						<InviteTypeForm
							nextStep={() => setStep(2)}
							onSubmit={onSubmit}
							value={value}
							inviteEmails={inviteEmails}
							setInviteEmails={setInviteEmails}
							tcTeam={tcTeam}
							tcAgent={tcAgent}
							setTcAgent={setTcAgent}
							setTcTeam={setTcTeam}
							setValue={setValue}
						/>
					)}
					{step === 2 && (
						<VendorInviteForm
							vendorList={vendorList}
							setVendorList={setVendorList}
							previousStep={() => setStep(1)}
							nextStep={() => setStep(3)}
							editingIndex={editingIndex}
							setEditingIndex={setEditingIndex}
							vendorData={vendorData}
							setVendorData={setVendorData}
							setTcTeam={setTcTeam}
							tcTeam={tcTeam}
							setTeamMembersSelected={setTcAgent}
							teamMembersSelected={tcAgent}
						/>
					)}
					{step === 3 && (
						<VendorInviteOverview
							vendorList={vendorList}
							setVendorList={setVendorList}
							setVendorData={setVendorData}
							previousStep={() => setStep(2)}
							setEditingIndex={setEditingIndex}
						/>
					)}
				</Center>
			</Box>
		</>
	)
}
