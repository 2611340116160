import { useState, useEffect } from "react"
import { SmallCloseIcon } from "@chakra-ui/icons"
import {
	Box,
	Button,
	Center,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Heading,
	HStack,
	IconButton,
	Input,
	InputGroup,
	InputLeftAddon,
	Stack,
	Text,
	Textarea,
} from "@chakra-ui/react"
import { Select } from "chakra-react-select"
import DatePicker from "react-datepicker"
import { collection, query, where, getDocs } from "firebase/firestore"
import { useContext } from "react"
import { UserContext } from "../Contexts/UserContext"
import { db } from "../firebase"
import { JobRequestResponseJobDetailsAccordion } from "./JobRequestResponseJobDetailsAccordion"
import { PaywallBanner } from "./PaywallBanner"
import "../select.css"
import "react-datepicker/dist/react-datepicker.css"
import "../date-picker.css"

export const JobRequestResponseForm = ({
	jobRequest,
	service,
	formError,
	setFormError,
	paywallTriggered,
	onSubmit,
}) => {
	const { userPlus } = useContext(UserContext)
	const [agentInfo, setAgentInfo] = useState(null)
	const [clientInfo, setClientInfo] = useState(null)
	const [responded, setResponded] = useState(false)

	useEffect(() => {
		if (!jobRequest) return
		const getAgentInfo = async () => {
			const q = query(
				collection(db, "users"),
				where("id", "==", jobRequest.agentId)
			)
			const doc = await getDocs(q)
			setAgentInfo(doc.docs[0].data().name)
		}
		const getClientInfo = async () => {
			const q = query(
				collection(db, "users"),
				where("id", "==", jobRequest.userId)
			)
			const doc = await getDocs(q)
			setClientInfo(doc.docs[0].data().name)
		}

		const getResponseInfo = async () => {
			const q = query(
				collection(db, "jobRequestResponses"),
				where("requestId", "==", jobRequest.id),
				where("userId", "==", userPlus.id)
			)
			const doc = await getDocs(q)
			if (doc.docs.length > 0) {
				setResponded(true)
				setFormData(doc.docs[0].data())
			}
		}
		if (jobRequest?.agentId) {
			getAgentInfo()
		}
		getClientInfo()
		getResponseInfo()
	}, [jobRequest])

	const [dateTimes, setDateTimes] = useState({
		date: null,
		startTime: "",
		endTime: "",
	})
	const [formData, setFormData] = useState({
		serviceCharge: null,
		servicePreferredDates: [],
		minQuote: null,
		maxQuote: null,
		serviceChargeCost: null,
		jobNotes: "",
	})
	const [availability, setAvailability] = useState(null)
	const [alternativeTimes, setAlternativeTimes] = useState(null)

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setDateTimes({ ...dateTimes, [name]: value })
	}

	const handleDateChange = (date) => {
		setDateTimes({ ...dateTimes, date })
	}

	const handleChange = (input, e) => {
		setFormData({ ...formData, [input]: e })
	}

	const handleAlternativeTimesChange = () => {
		setAvailability(true)
		setAlternativeTimes(true)
	}

	const handleConfirmAvailabilityChange = () => {
		setAvailability(true)
		setAlternativeTimes(false)
	}

	const handleAddEntry = () => {
		if (dateTimes.date && dateTimes.startTime && dateTimes.endTime) {
			handleChange("servicePreferredDates", [
				...formData.servicePreferredDates,
				dateTimes,
			])
			setDateTimes({ date: null, startTime: "", endTime: "" })
		} else {
			alert("Please fill in all fields.")
		}
	}

	const handleServiceTimeRemoval = (index) => {
		const newServicePreferredDates = [...formData.servicePreferredDates]
		newServicePreferredDates.splice(index, 1)
		setFormData({
			...formData,
			servicePreferredDates: newServicePreferredDates,
		})
	}

	const handleGenericSelectChange = (selectedOptions, fieldName) => {
		if (Array.isArray(selectedOptions)) {
			setFormData({
				...formData,
				[fieldName]: selectedOptions.map((option) => option.value),
			})
		} else {
			setFormData({
				...formData,
				[fieldName]: selectedOptions.value,
			})
		}
	}

	const formatTime = (timeString) => {
		const [hourString, minute] = timeString.split(":")
		const hour = +hourString % 24
		return (hour % 12 || 12) + ":" + minute + (hour < 12 ? "AM" : "PM")
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		if (
			dateTimes.date !== null &&
			dateTimes.startTime !== "" &&
			dateTimes.endTime !== ""
		) {
			setFormData((prevFormData) => {
				const updatedFormData = {
					...prevFormData,
					servicePreferredDates: [
						...prevFormData.servicePreferredDates,
						dateTimes,
					],
				}
				setDateTimes({ date: null, startTime: "", endTime: "" })

				// Call onSubmit with the updated formData
				onSubmit(e, availability, updatedFormData, alternativeTimes)

				return updatedFormData
			})
		} else {
			// Call onSubmit with the current formData if no new dates are added
			onSubmit(e, availability, formData, alternativeTimes)
		}
	}

	return (
		<>
			{paywallTriggered && (
				<PaywallBanner location="vendorJobRequestResponse" />
			)}
			<Center maxW="585px" margin={{ base: "15px", md: "auto" }}>
				<Stack gap={5} mt={{ base: "20px", md: "40px" }}>
					{paywallTriggered ? (
						<>
							{" "}
							<Heading size="lg" fontWeight="bold">
								Upgrade your Quiplo account
							</Heading>{" "}
							<Text>
								You must upgrade your subscription and have this service to get
								this job request details and respond.
							</Text>
						</>
					) : (
						<>
							<Heading size="lg" fontWeight="bold">
								{service?.serviceType} Request
							</Heading>

							<Text>
								{jobRequest.agentId
									? `You received a new job request from ${clientInfo}, client of ${agentInfo}. The client will follow up with you if there are any questions or wants to schedule.`
									: `You received a new job request from ${
											clientInfo ? clientInfo : jobRequest.userEmail
									  }. The agent will follow up with you if there are any questions or wants to schedule.`}
							</Text>
							<JobRequestResponseJobDetailsAccordion jobRequest={jobRequest} />
						</>
					)}
					<Button
						onClick={() => handleConfirmAvailabilityChange()}
						colorScheme={availability && !alternativeTimes ? "green" : "gray"}
					>
						Yes, our team is available
					</Button>
					<Button
						onClick={() => handleAlternativeTimesChange()}
						colorScheme={availability && alternativeTimes ? "green" : "gray"}
					>
						Our team is available at other times
					</Button>
					<Button
						onClick={() => setAvailability(false)}
						colorScheme={availability === false ? "red" : "gray"}
					>
						Sorry, we don't have availability
					</Button>
					{availability === null && formError && (
						<FormErrorMessage>Availability is required.</FormErrorMessage>
					)}
					{(availability === true || availability === null) && (
						<>
							<FormControl
								isInvalid={formError && formData.serviceCharge === null}
							>
								<FormLabel>
									Is there a service charge to go out and quote this job?
								</FormLabel>
								<Select
									options={[
										{ value: true, label: "yes" },
										{ value: false, label: "no" },
									]}
									value={
										formData.serviceCharge !== null
											? formData.serviceCharge
												? { value: true, label: "yes" }
												: { value: false, label: "no" }
											: null
									}
									onChange={(e) =>
										handleGenericSelectChange(e, "serviceCharge")
									}
								/>
								{formError && formData.serviceCharge === null && (
									<FormErrorMessage>
										Service Charge is required.
									</FormErrorMessage>
								)}
							</FormControl>
							{formData.serviceCharge === true && (
								<FormControl
									isInvalid={
										formError &&
										availability &&
										formData.serviceCharge &&
										!formData.serviceChargeCost
									}
								>
									<FormLabel>Enter service charge cost</FormLabel>
									<InputGroup>
										<InputLeftAddon children="$" />
										<Input
											type="number"
											bgColor="white"
											value={formData.serviceChargeCost}
											onChange={(e) =>
												handleChange("serviceChargeCost", e.target.value)
											}
											placeholder="Enter service charge cost"
										/>
									</InputGroup>
									{formError &&
										formData.serviceCharge === true &&
										!formData.serviceChargeCost && (
											<FormErrorMessage>
												Service Charge is required.
											</FormErrorMessage>
										)}
								</FormControl>
							)}
							{formData.serviceCharge === false && (
								<FormControl
									isInvalid={
										formError &&
										formData.serviceCharge === false &&
										(formData.minQuote === null || formData.maxQuote === null)
									}
								>
									<FormLabel>Enter non-binding job estimate</FormLabel>
									<Box display="flex">
										<InputGroup>
											<InputLeftAddon children="$" />
											<Input
												isInvalid={
													formError &&
													formData.serviceCharge === false &&
													formData.minQuote === null
												}
												type="number"
												bgColor="white"
												value={formData.minQuote}
												onChange={(e) =>
													handleChange("minQuote", e.target.value)
												}
												placeholder="Min"
											/>
										</InputGroup>
										<Text px="40px" m="auto" fontSize="xl">
											-
										</Text>
										<InputGroup>
											<InputLeftAddon children="$" />
											<Input
												isInvalid={
													formError &&
													formData.serviceCharge === false &&
													formData.maxQuote === null
												}
												type="number"
												bgColor="white"
												value={formData.maxQuote}
												onChange={(e) =>
													handleChange("maxQuote", e.target.value)
												}
												placeholder="Max"
											/>
										</InputGroup>
									</Box>
									{formError &&
										formData.serviceCharge === false &&
										(formData.minQuote === null ||
											formData.maxQuote === null) && (
											<FormErrorMessage>
												An estimate range is required.
											</FormErrorMessage>
										)}
								</FormControl>
							)}
							<FormControl>
								<FormLabel>Enter job notes</FormLabel>
								<Textarea
									value={formData.jobNotes}
									bgColor="white"
									onChange={(e) => handleChange("jobNotes", e.target.value)}
									placeholder="Share job notes with requester (optional)."
								/>
							</FormControl>
							{alternativeTimes && (
								<>
									{" "}
									<FormControl
										isInvalid={
											formError &&
											alternativeTimes === true &&
											formData.servicePreferredDates.length === 0
										}
									>
										<FormLabel>Alternative Times</FormLabel>
										<DatePicker
											w="100%"
											h="40px"
											border-radius="md"
											selected={dateTimes.date}
											onChange={handleDateChange}
											placeholderText="Select date"
											dateFormat="MM/dd/yyyy"
										/>
										{formError &&
											alternativeTimes === true &&
											formData.servicePreferredDates.length === 0 && (
												<FormErrorMessage>
													An alternative date is required.
												</FormErrorMessage>
											)}
									</FormControl>
									<HStack my="15px" gap={2} w="100%">
										<FormControl>
											<Input
												type="time"
												name="startTime"
												value={dateTimes.startTime}
												variant="jobRequest"
												onChange={handleInputChange}
											/>
										</FormControl>

										<FormControl>
											<Input
												type="time"
												name="endTime"
												value={dateTimes.endTime}
												onChange={handleInputChange}
												variant="jobRequest"
											/>
										</FormControl>
									</HStack>
									<Box>
										<Button onClick={handleAddEntry}>Add Time Slot</Button>
										<Box mt="15px">
											{formData.servicePreferredDates.map((entry, index) => (
												<HStack gap={5} mb="5px" key={index}>
													<Text>{entry.date.toLocaleDateString("en-US")}</Text>
													<Text>
														{formatTime(entry.startTime)} -{" "}
														{formatTime(entry.endTime)}
													</Text>
													<IconButton
														bgColor="background"
														icon={<SmallCloseIcon />}
														onClick={() => handleServiceTimeRemoval(index)}
													/>
												</HStack>
											))}
										</Box>
									</Box>
								</>
							)}
						</>
					)}
					<Button
						isDisabled={paywallTriggered || responded}
						w="100%"
						bgColor="borderColor"
						color="white"
						mt="20px"
						onClick={(e) =>
							handleSubmit(e, availability, formData, alternativeTimes)
						}
					>
						Submit
					</Button>
					<Text mb="20px">
						By submitting, this does not guarantee the job. The team will
						follow-up with any questions and confirm the appointment.{" "}
					</Text>
				</Stack>
			</Center>
		</>
	)
}
