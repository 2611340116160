module.exports = {
	PLAN: {
		AGENT_GROWING: "GROWING - For Agents",
		AGENT_ENTERPRISE: "ENTERPRISE - For Agents",
		VENDOR_BASE: "BASE - For Vendor",
		VENDOR_GROWING: "GROWING - For Vendor",
		VENDOR_SCALING: "SCALING - For Vendor",
	},
	JOB_REQUEST_LIMIT: 5,
	HPV_DEFAULT_VENDOR_ID: "6fc8b2e4-8543-49ab-869c-42d02ed0a8ad",
	HPV_DEFAULT_AGENT_ID: "f47ddcb0-1832-480d-b84d-937e3b0fc88f",
}
