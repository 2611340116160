import { useContext } from "react"
import {
	Box,
	Card,
	CardHeader,
	CardBody,
	HStack,
	Stack,
	StackDivider,
	Text,
	Tag,
	TagLabel,
	Image,
} from "@chakra-ui/react"
import { StarIcon } from "@chakra-ui/icons"
import { HomeProValetBadge } from "./HomeProValetBadge"
import { VerifiedBadge } from "./VerifiedBadge"
import { StarRating } from "./StarRating"
import { ServiceIcon } from "./ServiceIcon"
import { UserContext } from "../Contexts/UserContext"
import { formatPhoneNumber } from "../Utilities"

export const VendorListMobileCard = ({
	service,
	index,
	teamPreferredServices,
	userPreferredServices,
	setTeamPreferred,
	setUserPreferred,
	requestJobFromVendor,
}) => {
	const { userPlus } = useContext(UserContext)
	const badgeSize = "40px"

	return (
		<Card w="100%" mb="20px" key={index}>
			<CardHeader fontWeight="semibold" pb="5px">
				<Box display="flex" justifyContent="space-between">
					<Box display="flex" direction="column" alignItems="flex-start">
						<ServiceIcon serviceType={service.serviceType} />
						<Stack ml="10px" gap={0}>
							<Box display="flex" alignItems="stretch">
								<Text mr="5px">{service.name}</Text>
								{service.subscriptionStatusActive && (
									<Box
										ml="5px"
										mr="5px"
										alignSelf={["flex-start", "flex-start", "center"]}
										width={badgeSize}
										height={badgeSize}
									>
										<VerifiedBadge id={index} />
									</Box>
								)}
								{service.partner === "HPV" && (
									<Box
										ml="5px"
										mr="5px"
										alignSelf={["flex-start", "flex-start", "center"]}
										width={badgeSize}
										height={badgeSize}
									>
										<HomeProValetBadge id={index} />
									</Box>
								)}
							</Box>
							<Text fontSize="sm" color="grey">
								{service.serviceType}
							</Text>{" "}
						</Stack>
					</Box>
					{userPlus?.isAdmin && (
						<StarIcon
							onClick={() => setTeamPreferred(service.id, service.userId)}
							color={
								teamPreferredServices.includes(service.id) ? "gold" : "grey"
							}
						/>
					)}
					{userPlus?.userType !== "client" && !userPlus?.isAdmin && (
						<StarIcon
							onClick={() => setUserPreferred(service.id, service.userId)}
							color={
								userPreferredServices.includes(service.id) ? "gold" : "grey"
							}
						/>
					)}
				</Box>
				{service.googleRating && service.googleReviewCount && (
					<Box display="flex" mt="10px">
						<Image
							src="/assets/google_logo.png"
							alt="google logo"
							w="20px"
							mr="10px"
						/>
						<StarRating
							rating={service.googleRating}
							reviewCount={service.googleReviewCount}
						/>
					</Box>
				)}
			</CardHeader>
			<CardBody fontSize="sm" pt="5px">
				<HStack mb="5px">
					{!userPlus?.isAdmin &&
						userPlus.userType === "agent" &&
						userPreferredServices.includes(service.id) && (
							<Tag
								width="125px"
								borderRadius="full"
								variant="solid"
								bgColor="green"
							>
								<TagLabel>Recommended</TagLabel>
							</Tag>
						)}

					{userPlus?.teamId &&
						!userPlus?.isAdmin &&
						teamPreferredServices.includes(service.id) && (
							<Tag
								width="125px"
								borderRadius="full"
								variant="solid"
								bgColor="darkBlue"
							>
								<TagLabel>Team Preferred</TagLabel>
							</Tag>
						)}
					{userPlus.userType === "client" &&
						userPreferredServices.includes(service.id) && (
							<Tag
								width="125px"
								borderRadius="full"
								variant="solid"
								bgColor="green"
							>
								<TagLabel>Agent Preferred</TagLabel>
							</Tag>
						)}
				</HStack>
				<Stack divider={<StackDivider />} spacing="1" mt="10px">
					<Text style={{ wordWrap: "break-word" }}>
						{service.contactEmail
							? service.contactEmail
							: formatPhoneNumber(service.phoneNumber)}
					</Text>
					<Box display="flex" justifyContent="space-between">
						<Text fontWeight="semibold">Number of Job Responses:</Text>
						<Text>
							{" "}
							{service.jobResponseCount ? service.jobResponseCount : 0}
						</Text>
					</Box>
				</Stack>
			</CardBody>
		</Card>
	)
}
