import {
	Box,
	Button,
	Card,
	CardHeader,
	CardBody,
	HStack,
	Stack,
	StackDivider,
	Text,
	Tag,
	TagLabel,
	useDisclosure,
} from "@chakra-ui/react"
import { JobRequestScheduleModal } from "./JobRequestScheduleModal"
import { HPVJobRequestScheduleModal } from "./HPVJobRequestScheduleModal"
import { HomeProValetBadge } from "./HomeProValetBadge"
import { CouponBadge } from "./CouponBadge"
import { dateToFormat, convertToAMPM } from "../Utilities"

export const JobRequestResponseMobileCard = ({
	jobRequestResponse,
	jobRequest,
	handleScheduling,
	selectedResponseId,
	sendCouponEmail,
	userPlus,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure()

	return (
		<Card w="100%" mb="20px" key={jobRequestResponse.id}>
			<CardHeader fontWeight="semibold">
				<HStack>
					<Text>{jobRequestResponse.vendorName}</Text>
					{jobRequestResponse.partner && (
						<HomeProValetBadge id={jobRequestResponse.id} />
					)}
					{jobRequestResponse.couponCode &&
						jobRequestResponse.couponCode !== "none" && (
							<CouponBadge
								id={jobRequestResponse.id}
								couponCode={jobRequestResponse.couponCode}
								couponAmount={jobRequestResponse.couponAmount}
								couponPercent={jobRequestResponse.couponPercent}
							/>
						)}
				</HStack>
			</CardHeader>
			<CardBody fontSize="sm">
				<Stack divider={<StackDivider />} spacing="1">
					<Text style={{ wordWrap: "break-word" }}>
						{jobRequestResponse.vendorContactEmail}
					</Text>

					<Box display="flex" justifyContent="space-between">
						<Text fontWeight="semibold">Job Estimate:</Text>
						{jobRequestResponse.minQuote && jobRequestResponse.maxQuote && (
							<Text>
								${jobRequestResponse.minQuote}-$
								{jobRequestResponse.maxQuote}
							</Text>
						)}
						{jobRequestResponse.serviceCharge && (
							<Box display="flex">
								<Text>${jobRequestResponse.serviceChargeCost}</Text>
								<Tag
									ml="10px"
									width="125px"
									borderRadius="full"
									variant="solid"
									bgColor="blue.300"
									color="blue.800"
								>
									<TagLabel>Service Charge</TagLabel>
								</Tag>
							</Box>
						)}
					</Box>
					<Box
						display="flex"
						flexDirection="column"
						justifyContent="space-between"
					>
						<Text fontWeight="semibold">Alternative Dates:</Text>
						{jobRequestResponse.servicePreferredDates &&
							jobRequestResponse.servicePreferredDates.map((entry, index) => (
								<Box key={index}>
									<Text align="right">{dateToFormat(entry.date)}</Text>
									<Text align="right">
										{" "}
										{convertToAMPM(entry.startTime)} -{" "}
										{convertToAMPM(entry.endTime)}
									</Text>
								</Box>
							))}
					</Box>
					<Box display="flex" justifyContent="space-between">
						<Text fontWeight="semibold">Vendor Status:</Text>
						{selectedResponseId === jobRequestResponse.id && (
							<>
								{!userPlus.partner && (
									<Text>
										Request to Schedule -{" "}
										{dateToFormat(jobRequest.scheduleDate)}
									</Text>
								)}
								{userPlus.partner && <Text>Request Booking</Text>}
							</>
						)}

						{selectedResponseId !== jobRequestResponse.id &&
							jobRequestResponse.availabile && <Text>Not Available</Text>}
						{selectedResponseId !== jobRequestResponse.id &&
							!jobRequestResponse.availabile && <Text> Available</Text>}
					</Box>
				</Stack>
				{jobRequest.selectedResponseId && (
					<Button
						w="100%"
						mt="10px"
						bgColor="borderColor"
						color="white"
						onClick={() => handleScheduling(jobRequestResponse)}
					>
						Undo scheudle
					</Button>
				)}
				{!jobRequest.selectedResponseId && !jobRequest.partner && (
					<Button
						w="100%"
						mt="10px"
						bgColor="borderColor"
						color="white"
						onClick={() => onOpen()}
					>
						Schedule
					</Button>
				)}
				{!jobRequest.selectedResponseId && jobRequest.partner && (
					<Button
						w="100%"
						mt="10px"
						bgColor="borderColor"
						color="white"
						onClick={() => onOpen()}
					>
						Schedule
					</Button>
				)}
				{jobRequestResponse.couponCode &&
					jobRequestResponse.couponCode !== "none" && (
						<Button
							onClick={() => sendCouponEmail(jobRequestResponse)}
							mt="10px"
							w="100%"
						>
							{userPlus.userType === "client" ||
							(userPlus.userType === "agent" &&
								jobRequest.clientIds.length === 0)
								? "Get Coupon"
								: "Send Coupon to Client"}
						</Button>
					)}
				{isOpen && !jobRequestResponse.partner && (
					<JobRequestScheduleModal
						handleScheduling={handleScheduling}
						jobRequest={jobRequest}
						jobRequestResponse={jobRequestResponse}
						isOpen={isOpen}
						onClose={onClose}
					/>
				)}
				{isOpen && jobRequestResponse.partner && (
					<HPVJobRequestScheduleModal
						jobRequest={jobRequest}
						jobRequestResponse={jobRequestResponse}
						isOpen={isOpen}
						onClose={onClose}
					/>
				)}
			</CardBody>
		</Card>
	)
}
