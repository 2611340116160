import React, { useContext } from "react"
import {
	Box,
	Card,
	CardHeader,
	CardBody,
	Stack,
	StackDivider,
	Text,
} from "@chakra-ui/react"
import { UserContext } from "../Contexts/UserContext"

export const JobRequestResponseAwaitingResponseMobileCard = ({ vendor }) => {
	const { userPlus } = useContext(UserContext)
	return (
		<Card w="100%" mb="20px" key={vendor.id}>
			<CardHeader fontWeight="semibold">
				{vendor.name ? vendor.name : vendor.email}
			</CardHeader>
			<CardBody fontSize="sm">
				<Stack divider={<StackDivider />} spacing="1">
					<Box display="flex" justifyContent="space-between">
						<Text fontWeight="semibold">
							{userPlus.partner ? "Home Pro Status:" : "Vendor Status:"}
						</Text>
						<Text>Awaiting Response</Text>
					</Box>
				</Stack>
			</CardBody>
		</Card>
	)
}
