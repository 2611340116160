import React, { useContext, useEffect, useState } from "react"
import {
	Box,
	Button,
	Heading,
	HStack,
	IconButton,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Text,
	Stack,
	Table,
	Tag,
	TagLabel,
	Thead,
	Tbody,
	Tr,
	Th,
	Td,
	useDisclosure,
} from "@chakra-ui/react"
import { UpDownIcon, EditIcon } from "@chakra-ui/icons"
import { Select } from "chakra-react-select"
import { db } from "../../firebase"
import { dateToFormat } from "../../Utilities"
import { useNavigate, NavLink } from "react-router-dom"
import {
	collection,
	endBefore,
	getDocs,
	query,
	where,
	startAt,
	limit,
	orderBy,
} from "firebase/firestore"
import { JobRequestsMobileCard } from "../../Components/JobRequestsMobileCard"
import { serviceTypesOptions } from "../../Options/ServiceTypes"
import { EmptyState } from "../../Components/EmptyState"
import { UserContext } from "../../Contexts/UserContext"
import { AgentPayWallModal } from "../../Components/AgentPayWall"
import { PaywallBanner } from "../../Components/PaywallBanner"
import { SkeletonTable } from "../../Components/SkeletonTable"
import { JOB_REQUEST_LIMIT } from "../../enums"

const AgentJobRequestListView = () => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	const { userPlus } = useContext(UserContext)
	const navigate = useNavigate()
	const [page, setPage] = useState(1)
	const pageSize = 10
	const [lastVisible, setLastVisible] = useState(null)
	const [hasMore, setHasMore] = useState(true)
	const [filtered, setFiltered] = useState(false)
	const [direction, setDirection] = useState("next")
	const [pageLoading, setPageLoading] = useState(false)
	const [clearFilters, setClearFilters] = useState(false)
	const [jobRequests, setJobRequests] = useState([])
	const [serviceTypeFilter, setServiceTypeFilter] = useState([])
	const [vendorFilterOptions, setVendorFilterOptions] = useState([])
	const [locationFilterOptions, setLocationFilterOptions] = useState([])
	const [vendorFilter, setVendorFilter] = useState([])
	const [statusFilter, setStatusFilter] = useState("")
	const [locationFilter, setLocationFilter] = useState([])
	const [paywallTriggered, setPaywallTriggered] = useState(false)
	const [hasOpened, setHasOpened] = useState(false)

	useEffect(() => {
		setClearFilters(true)
	}, [userPlus.teamId])

	useEffect(() => {
		if (userPlus === "pending" || !userPlus) {
			return // Early return if user status is pending or not available
		}

		const fetchJobRequests = async () => {
			try {
				setPageLoading(true) // Set page loading to true before fetching data

				// Reset filters if clearFilters flag is true
				if (clearFilters === true) {
					setClearFilters(false)
					setFiltered(false)
					setPage(1)
					setDirection("next")
					setLastVisible(null)
					resetFilters() // Reset any additional filters
				}

				// Construct the query based on user's role and pagination direction
				const q = query(
					collection(db, "jobRequests"),
					where(
						userPlus.isAdmin ? "teamId" : "userId",
						"==",
						userPlus.isAdmin ? userPlus.teamId : userPlus.id
					),
					orderBy("_createdAt"),
					direction === "next" ? startAt(lastVisible) : endBefore(lastVisible),
					limit(pageSize + 1)
				)
				const doc = await getDocs(q)
				if (!doc.empty) {
					const jobRequestsData = doc.docs.map((doc) => ({
						id: doc.id,
						...doc.data(),
					}))

					setJobRequests(jobRequestsData.slice(0, pageSize)) // Update job requests
					setLastVisible(doc.docs[doc.docs.length - 1]) // Update last visible document
					setHasMore(doc.docs.length > pageSize) // Determine if there are more documents
				} else {
					// If there are no documents, reset job requests and set hasMore to false
					setJobRequests([])
					setHasMore(false)
				}

				setFiltered(false) // Reset filtered state
				setPageLoading(false) // Set page loading to false after fetching data
			} catch (error) {
				console.error("Error fetching jobRequests:", error)
				setPageLoading(false) // Set page loading to false in case of error
				navigate("/login") // Redirect to login page on error
			}
		}
		fetchJobRequests()
	}, [userPlus, clearFilters, page, direction])

	const getVendorList = async () => {
		let q
		if (userPlus.isAdmin) {
			q = query(
				collection(db, "jobRequests"),
				where("teamId", "==", userPlus.teamId)
			)
		} else {
			q = query(
				collection(db, "jobRequests"),
				where("userId", "==", userPlus.id)
			)
		}
		const docs = await getDocs(q)
		const vendorIds = [
			...new Set(docs.docs.flatMap((doc) => doc.data().vendorIds)),
		]

		const vendorResults = await Promise.all(
			vendorIds.map(async (item) => {
				const q = query(collection(db, "users"), where("id", "==", item))
				const querySnapshot = await getDocs(q)
				return querySnapshot.docs.map((doc) => doc.data())
			})
		)
		const vendorResultsFlat = vendorResults.flat()
		const vendorOptions = vendorResultsFlat.map((item) => ({
			label: item.name ? item.name : item.email,
			value: item.id,
		}))
		setVendorFilterOptions(vendorOptions)
	}

	const getLocationFilter = async () => {
		const q = query(
			collection(db, "jobRequests"),
			where(
				userPlus.isAdmin ? "teamId" : "userId",
				"==",
				userPlus.isAdmin ? userPlus.teamId : userPlus.id
			)
		)
		const doc = await getDocs(q)
		const locationOptions = Array.from(
			new Set(doc.docs.map((doc) => doc.data().locationAddress))
		).map((item) => ({
			label: item,
			value: item,
		}))
		setLocationFilterOptions(locationOptions)
	}

	const resetFilters = () => {
		setServiceTypeFilter([])
		setVendorFilter([])
		setStatusFilter("")
		setLocationFilter([])
	}

	const openFilters = async () => {
		if (!hasOpened) {
			await getVendorList()
			await getLocationFilter()
			setHasOpened(true)
		}
		onOpen()
	}

	const getFilteredList = async () => {
		try {
			let uniqueServicesMap = new Map() // Use a Map for uniqueness
			let filteredJobRequests = []
			// Create a base query with the common condition for isActive
			let baseQuery
			if (userPlus.isAdmin) {
				baseQuery = query(
					collection(db, "jobRequests"),
					where("teamId", "==", userPlus.teamId)
				)
			} else {
				baseQuery = query(
					collection(db, "jobRequests"),
					where("userId", "==", userPlus.id)
				)
			}
			// Add conditions based on serviceTypeFilter
			if (serviceTypeFilter.length > 0) {
				baseQuery = query(
					baseQuery,
					where("serviceType", "in", serviceTypeFilter)
				)
			}

			if (locationFilter.length > 0) {
				baseQuery = query(
					baseQuery,
					where("locationAddress", "==", locationFilter)
				)
			}
			let results = []
			// Filter based on vendorFilter
			if (vendorFilter.length > 0) {
				const vendorResults = await Promise.all(
					vendorFilter.map(async (item) => {
						const q = query(
							baseQuery,
							where("vendorIds", "array-contains", item)
						)
						const querySnapshot = await getDocs(q)
						return querySnapshot.docs
					})
				)
				results = [...results, ...vendorResults.flat()]
			}
			if (vendorFilter.length === 0) {
				const doc = await getDocs(baseQuery)
				results = doc.docs
			}
			// Clear the existing uniqueServicesMap
			uniqueServicesMap.clear()

			// Populate the uniqueServicesMap with new data
			results.flat().forEach((doc) => {
				const service = {
					id: doc.id,
					...doc.data(),
				}
				uniqueServicesMap.set(service.id, service)
			})

			filteredJobRequests = Array.from(uniqueServicesMap.values())

			if (statusFilter !== "") {
				if (statusFilter === "scheduled") {
					filteredJobRequests = filteredJobRequests.filter(
						(request) => request.selectedResponseId
					)
				}
				if (statusFilter === "inProgress") {
					filteredJobRequests = filteredJobRequests.filter(
						(request) =>
							request.vendorsAvailable >= 1 && !request.selectedResponseId
					)
				}
				if (statusFilter === "pending") {
					filteredJobRequests = filteredJobRequests.filter(
						(request) => !request.vendorsAvailable
					)
				}
			}
			setFiltered(true)
			setJobRequests(filteredJobRequests)
			onClose()
		} catch (error) {
			console.error("Error filtering job requests:", error)
		}
	}

	const goToNext = () => {
		setPage(page + 1)
		setDirection("next")
	}

	const goToPrevious = () => {
		setPage(page - 1)
		setDirection("previous")
	}

	const startNewJobRequest = () => {
		if (
			userPlus.userType === "agent" &&
			!userPlus.activePlan &&
			!userPlus.partner &&
			userPlus.jobRequestsMade >= JOB_REQUEST_LIMIT
		) {
			setPaywallTriggered(true)
			return
		} else {
			navigate("/job-requests/new")
		}
	}

	const statusFilterOptions = [
		{ label: "Request Booking Jobs", value: "scheduled" },
		{ label: "Jobs With Bids Received", value: "inProgress" },
		{ label: "Requests Awaiting Responses", value: "pending" },
		{ label: "All Job Requests", value: "" },
	]

	if (userPlus === null) {
		navigate("/login")
	}

	return (
		<>
			{userPlus.planName === null && userPlus.userType === "agent" && (
				<PaywallBanner location="AgentJobRequestList" />
			)}
			<Box
				ml={{ base: "15px", lg: "93px" }}
				mr={{ base: "15px", lg: "93px" }}
				mt="30px"
			>
				<Heading size="lg" fontWeight="bold">
					Job Requests
				</Heading>
				<Button
					onClick={() => startNewJobRequest()}
					display={["flex", "flex", "none", "none"]}
					mt="20px"
					w="100%"
					bgColor="borderColor"
					color="white"
				>
					{userPlus.partner ? "Contact Home Pros" : "Contact Vendors"}
				</Button>
				<HStack
					display={["none", "none", "flex", "flex"]}
					justifyContent="space-between"
					mt="10px"
				>
					<Text>Track and manage your open job requests</Text>
					<Button onClick={() => startNewJobRequest()}>
						{userPlus.partner ? "Contact Home Pros" : "Contact Vendors"}
					</Button>
				</HStack>
				<Box
					display={["flex", "flex", "none", "none"]}
					mt="20px"
					flexDirection="column"
				>
					<Box display="flex" justifyContent="flex-end" mb="15px">
						<Button
							onClick={() => openFilters()}
							rightIcon={<UpDownIcon />}
							bgColor="background"
							mt="15px"
						>
							Filter
						</Button>
						<Button onClick={() => setClearFilters(true)} mt="15px">
							Clear Filters
						</Button>
					</Box>
					{jobRequests.length === 0 && (
						<EmptyState
							helperText="You have not submitted any job requests yet!"
							partner={userPlus.partner}
						/>
					)}
					{jobRequests.map((jobRequest, index) => {
						return (
							<JobRequestsMobileCard
								jobRequest={jobRequest}
								index={index}
								key={index}
							/>
						)
					})}
				</Box>
				<Box
					display={["none", "none", "flex", "flex"]}
					flexDirection="column"
					mt="30px"
				>
					<Box display="flex" justifyContent="flex-end" gap={4}>
						<Button onClick={() => setClearFilters(true)} mt="15px">
							Clear Filters
						</Button>
						<Button
							onClick={() => openFilters()}
							rightIcon={<UpDownIcon />}
							bgColor="blue.900"
							color="white"
							mt="15px"
						>
							Filter
						</Button>
					</Box>
					{pageLoading && <SkeletonTable />}
					{!pageLoading && (
						<Table variant="simple" mt="40px">
							<Thead>
								<Tr>
									<Th>Location Address</Th>
									<Th>Service Type</Th>
									<Th>Preferred Dates</Th>
									<Th>Job Status</Th>
									<Th>Details</Th>
								</Tr>
							</Thead>
							<Tbody>
								{jobRequests.map((jobRequest) => {
									return (
										<Tr key={jobRequest.id}>
											<Td>{jobRequest.locationAddress}</Td>
											<Td>{jobRequest.serviceType}</Td>
											<Td>
												{jobRequest.servicePreferredDates.map(
													(entry, index) => (
														<HStack gap={5} mb="5px" key={index}>
															<Text key={index}>
																{dateToFormat(entry.date)}
															</Text>
														</HStack>
													)
												)}
											</Td>
											<Td>
												{jobRequest.selectedResponseId && (
													<Tag
														borderRadius="full"
														variant="solid"
														bgColor="green"
														mr="5px"
														mb="5px"
													>
														{!userPlus.partner && (
															<TagLabel>
																Request to Schedule -{" "}
																{dateToFormat(jobRequest.scheduleDate)}
															</TagLabel>
														)}
														{userPlus.partner && (
															<TagLabel>Request Booking</TagLabel>
														)}
													</Tag>
												)}
												{jobRequest.vendorsAvailable >= 1 &&
													!jobRequest.selectedResponseId && (
														<Tag
															borderRadius="full"
															variant="solid"
															bgColor="
														#FDE8C8"
															color="black"
															mr="5px"
															mb="5px"
														>
															<TagLabel>In Progress</TagLabel>
														</Tag>
													)}
												{!jobRequest.vendorsAvailable && (
													<Tag
														borderRadius="full"
														variant="solid"
														bgColor="
													#gray.500"
														mr="5px"
														mb="5px"
													>
														<TagLabel>Awaiting Responses</TagLabel>
													</Tag>
												)}
											</Td>
											<Td>
												<IconButton
													as={NavLink}
													bgColor="background"
													to={`/job-requests/responses/${jobRequest.id}`}
													aria-label="Respond to Job Request"
													icon={<EditIcon />}
												/>
											</Td>
										</Tr>
									)
								})}
							</Tbody>
						</Table>
					)}
					{jobRequests.length === 0 && (
						<EmptyState
							partner={userPlus.partner}
							helperText="You have not submitted any job requests yet!"
						/>
					)}
				</Box>
				{jobRequests.length > 0 && (
					<Box display="flex" justifyContent="flex-end" gap={3} my="15px">
						<Button
							onClick={() => goToPrevious()}
							isDisabled={page === 1 || filtered}
						>
							Previous
						</Button>
						<Button
							onClick={() => goToNext()}
							isDisabled={!hasMore || filtered}
						>
							Next
						</Button>
					</Box>
				)}
			</Box>
			<Modal
				isOpen={isOpen}
				onClose={onClose}
				size={{ base: "full", lg: "2xl" }}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>Filter Job Requests</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack gap={2}>
							<Box>
								<Select
									placeholder="Location Address"
									value={locationFilterOptions.filter((option) =>
										locationFilter.includes(option.value)
									)}
									options={locationFilterOptions}
									onChange={(option) => {
										setLocationFilter(option.value)
									}}
								/>
							</Box>
							<Box>
								<Select
									placeholder="Service Type"
									value={serviceTypesOptions.filter((option) =>
										serviceTypeFilter.includes(option.value)
									)}
									isMulti
									options={serviceTypesOptions}
									onChange={(options) => {
										setServiceTypeFilter(options.map((option) => option.value))
									}}
								/>
							</Box>
							<Box>
								<Select
									placeholder="Vendors"
									value={vendorFilterOptions.filter((option) =>
										vendorFilter.includes(option.value)
									)}
									isMulti
									options={vendorFilterOptions}
									onChange={(options) => {
										setVendorFilter(options.map((option) => option.value))
									}}
								/>
							</Box>
							<Box>
								<Select
									placeholder="Job Request Status"
									value={statusFilterOptions.filter(
										(option) => option.value === statusFilter
									)}
									options={statusFilterOptions}
									onChange={(options) => {
										setStatusFilter(options.value)
									}}
								/>
							</Box>
						</Stack>
					</ModalBody>
					<ModalFooter margin="auto">
						<Button
							bgColor="borderColor"
							color="white"
							onClick={getFilteredList}
						>
							Apply Filters
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<AgentPayWallModal
				isOpen={paywallTriggered}
				setPaywallTriggered={setPaywallTriggered}
			/>
		</>
	)
}

export default React.memo(AgentJobRequestListView)
