import { NavLink } from "react-router-dom"
import {
	Button,
	FormControl,
	FormLabel,
	Link,
	RadioGroup,
	Radio,
	Stack,
	Text,
	VStack,
} from "@chakra-ui/react"

export const RegistrationFirstStep = ({
	formData,
	nextStep,
	handleChange,
	id,
	partner,
	isHPV,
}) => {
	return (
		<FormControl>
			<VStack gap={2} alignItems="left">
				<FormControl>
					<FormLabel>What best describes you?</FormLabel>
					<RadioGroup
						value={formData.userType}
						onChange={(e) => handleChange("userType", e)}
						mb="30px"
					>
						<Stack direction="column">
							<Radio value="agent">
								<Text>Agent / Transaction Coordinator</Text>
							</Radio>
							<Radio value="vendor">
								<Text>Skilled Vendor</Text>
							</Radio>
							<Radio value="client">
								<Text>Homeowner</Text>
							</Radio>
						</Stack>
					</RadioGroup>
				</FormControl>
				{formData.userType === "client" && !id && !partner && (
					<Text color="blue.700">
						Contact your real estate agent to send you an invite to Quiplo.
					</Text>
				)}
				<Button
					w="100%"
					bgColor={isHPV ? "#383d93" : "borderColor"}
					color="white"
					onClick={nextStep}
					isDisabled={formData.userType === "client" && !id && !partner}
				>
					Continue
				</Button>
				<Text fontSize="15px">
					Already have an account?{" "}
					<Link as={NavLink} to={"/login"}>
						Login
					</Link>
				</Text>
			</VStack>
		</FormControl>
	)
}
